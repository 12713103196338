/* eslint-disable react/prop-types */
import { get } from 'lodash';

const generateObjectDependencies = (object, nameOrPath) => {
  let valuesSelector = {};
  nameOrPath.map(
    ({ path, values }) =>
      Array.isArray(values) &&
      values.forEach((value) => {
        valuesSelector = {
          ...valuesSelector,
          [value]: path ? object[path]?.[value] : object[value] ?? null,
        };
      }),
  );
  return valuesSelector;
};

export const originalLoanInfoSelector =
  (nameOrPath, defaultValue = null) =>
  ({ EPPS: { originalLoanInformation } }) => {
    if (typeof nameOrPath === 'string')
      return {
        value: get(originalLoanInformation, nameOrPath) ?? defaultValue,
      };
    if (Array.isArray(nameOrPath))
      return generateObjectDependencies(originalLoanInformation, nameOrPath);
    return null;
  };

export const formValidationsSelector =
  (nameOrPath, defaultValue = null) =>
  ({
    eppsData: { searchForm: { formValidations } = { formValidations: {} } },
  }) => {
    if (typeof nameOrPath === 'string')
      return get(formValidations, nameOrPath, defaultValue);
    if (Array.isArray(nameOrPath)) {
      let formValidationData = {};
      nameOrPath.forEach((path) => {
        formValidationData = {
          ...formValidationData,
          [path]: formValidations[path],
        };
      });
      return formValidationData;
    }
    return null;
  };

// loanData Selector

export const loanDataFieldSelector =
  (nameOrPath, defaultValue = null) =>
  ({ EPPS: { loanInfo = {} } }) => {
    if (typeof nameOrPath === 'string')
      return { value: get(loanInfo, nameOrPath) ?? defaultValue };
    if (Array.isArray(nameOrPath))
      return generateObjectDependencies(loanInfo, nameOrPath);
    return nameOrPath ? null : loanInfo;
  };

// searchForm selector

export const searchFormSelector =
  (nameOrPath, defaultValue = null) =>
  ({ eppsData: { searchForm = {} } }) => {
    if (typeof nameOrPath === 'string')
      return { value: get(searchForm, nameOrPath) ?? defaultValue };
    if (Array.isArray(nameOrPath))
      return generateObjectDependencies(searchForm, nameOrPath);
    return null;
  };

// lookups selector

export const lookupsSelector =
  (lookupName?) =>
  ({ eppsData: { lookups: { data = {} } = {} } }) => {
    if (lookupName) {
      const getName = String(lookupName).replace(/[0-9]/g, '').replace('-', '');
      return get(data, getName);
    }
    return lookupName ? [] : data;
  };

export const loanIdSelector =
  () =>
  ({ EPPS, epps }) =>
    EPPS?.loanInfo?.loanInformation?.loanId ||
    EPPS?.loanPrograms?.loanId ||
    epps?.lockSummary?.loanId;

// LockSummary selector

export const lockSummarySelector =
  (nameOrPath, defaultValue = null) =>
  ({ eppsData: { lookSummary = {} } }) => {
    if (typeof nameOrPath === 'string')
      return { value: get(lookSummary, nameOrPath) ?? defaultValue };
    if (Array.isArray(nameOrPath))
      return generateObjectDependencies(lookSummary, nameOrPath);
    return null;
  };

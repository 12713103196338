import { DSFormItemLayout, DSLargeInputText } from '@elliemae/ds-form';
import { GridContainer, GridItem } from '@elliemae/ds-grid';
import { DSTextWrapper } from '@elliemae/ds-text-wrapper';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Session, {
  IS_BUYSIDE,
  IS_SELLSIDE,
} from '../../../../common/services/Session';
import { setComments } from '../../../../data/lockSummary/actions';
import { useLockRequestComments } from '../../../../data/useSelectors';
import { useLoanDataFieldSelector } from '../../../components/Form/utils/customHooks';
import { commentSectionMessage } from './constants';
import { StyledCommentSectionContainer } from './styled';

export const CommentsSection = ({ isForPrint }) => {
  const dispatch = useDispatch();
  const requestComments = useLockRequestComments();
  const loanData = useLoanDataFieldSelector() as any;
  const isLockUpdate =
    loanData.requestAction === 2 || loanData.requestLockStatus === 2;
  const [value, setValue] = useState('');
  const isBuyside = Session.get(IS_BUYSIDE);
  const isSellside = Session.get(IS_SELLSIDE);

  useEffect(() => {
    if (isBuyside || isSellside || isLockUpdate) {
      setValue('');
    } else {
      setValue(requestComments);
    }
  }, [requestComments]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = (e) => {
    const commentsValue = e.target.value.trim() || '';
    setValue(e.target.value);
    dispatch(setComments(commentsValue));
  };

  return (
    <StyledCommentSectionContainer style={{ breakInside: 'avoid' }}>
      <GridContainer cols={2}>
        <GridItem span={1}>
          <DSFormItemLayout
            value={value}
            maxLength={2000}
            labelText="Comments"
            onChange={handleChange}
            feedbackMessage="Optional"
            characterLimitCounter={2000}
            inputComponent={DSLargeInputText}
            onBlur={handleBlur}
          />
        </GridItem>
        <GridItem span={2}>
          <DSTextWrapper
            containerProps={{ id: 'lockSummary-textWrapper' }}
            text={!isBuyside && !isSellside && commentSectionMessage}
            style={
              isForPrint
                ? { color: '#8C93A6', fontSize: '11px' }
                : { marginBottom: '50px' }
            }
          />
        </GridItem>
      </GridContainer>
    </StyledCommentSectionContainer>
  );
};

CommentsSection.defaultProps = {
  isForPrint: false,
};

CommentsSection.propTypes = {
  isForPrint: PropTypes.bool,
};

import { Grid } from '@elliemae/ds-grid';
import { ChevronLeft, ChevronRight, ENCDarkWhite } from '@elliemae/ds-icons';
import { DSPageHeaderV2 } from '@elliemae/ds-page-header';
import { DSToolbar, ToolbarGroup } from '@elliemae/ds-toolbar';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import Session, {
  APPLICATION_NAME,
  IS_LO,
} from '../../../../common/services/Session';
import {
  useLoanDataFieldSelector as useLoanDataFieldSelectorHook,
  useLookupOptions,
} from '../../Form/utils/customHooks';

import { HeaderMenu } from '../HeaderMenu';
import {
  StyledArrowButtons,
  StyledCarouselContent,
  StyledColorContainer,
  StyledDebugInfo,
  StyledSlash,
} from './styled';
import { TopRibbon } from './TopRibbon';

export const SmartClientHeader = ({
  loanId,
  username,
  office,
  loanNumber,
  schemaForm,
  showCustomSearch,
}) => {
  const applicationName = Session.get(IS_LO)
    ? Session.get(APPLICATION_NAME) || 'ENCW'
    : 'EPPS';
  const eppsUserName = useLoanDataFieldSelectorHook('eppsUserName') as any;
  const officersOptions = useLookupOptions('transformedOfficeUsers');
  const loanOfficer =
    eppsUserName && officersOptions
      ? officersOptions.find((option) => option.login === eppsUserName.value)
      : null;
  const displayName = loanOfficer?.label ?? `${username}`;
  const renderToolbar = (
    <DSToolbar
      withDepth={false}
      autoFocusOnMount={false}
      size="compact"
      style={{ marginRight: '4px' }}
    >
      <ToolbarGroup>
        <>
          <StyledDebugInfo>
            {`${displayName} ${office ? `, ${office}` : ''}`}
          </StyledDebugInfo>
          {username || office ? <StyledSlash /> : ''}
          {loanId ? (
            <>
              <StyledDebugInfo data-testid="SmartClientHeader-all-components">{`Pricing ID: ${String(
                loanId,
              )}`}</StyledDebugInfo>
              <StyledSlash />
            </>
          ) : (
            ''
          )}
          {loanNumber ? (
            <StyledDebugInfo>{`Loan #${loanNumber}`}</StyledDebugInfo>
          ) : (
            ''
          )}
        </>
      </ToolbarGroup>
    </DSToolbar>
  );

  const handleClickRight = () => {
    const elementToScroll = document.querySelector('#top-ribbon-body');
    if (elementToScroll) {
      elementToScroll.scrollLeft += 100;
    }
  };

  const handleClickLeft = () => {
    const elementToScroll = document.querySelector('#top-ribbon-body');
    if (elementToScroll) {
      elementToScroll.scrollLeft -= 100;
    }
  };

  return (
    <>
      <StyledColorContainer>
        <DSPageHeaderV2
          pageTitle={<ENCDarkWhite height={25} style={{ marginLeft: '4px' }} />}
          toolbar={renderToolbar}
          withBottomSeparator={false}
          summary={<div />}
          menu={<div />}
        />
      </StyledColorContainer>
      <HeaderMenu
        loanId={loanId}
        applicationName={applicationName}
        schemaForm={schemaForm}
        showCustomSearch={showCustomSearch}
      />
      <Grid
        id="smart-client-ribbon"
        wrap="nowrap"
        cols={['24px', '1fr', '24px']}
        gutter="0px"
      >
        <Grid wrap="nowrap">
          <StyledArrowButtons
            size="s"
            buttonType="text"
            data-testid="carousel-go-left"
            aria-label="Go Left"
            leftIcon={<ChevronLeft />}
            onClick={handleClickLeft}
          />
        </Grid>
        <StyledCarouselContent wrap="nowrap" id="top-ribbon-body">
          <TopRibbon />
        </StyledCarouselContent>
        <Grid wrap="nowrap">
          <StyledArrowButtons
            size="s"
            buttonType="text"
            data-testid="carousel-go-right"
            aria-label="Go Right"
            leftIcon={<ChevronRight />}
            onClick={handleClickRight}
          />
        </Grid>
      </Grid>
    </>
  );
};

SmartClientHeader.defaultProps = {
  loanId: 0,
  loanNumber: '',
  username: '',
  office: '',
};

SmartClientHeader.propTypes = {
  loanId: PropTypes.number,
  loanNumber: PropTypes.string,
  username: PropTypes.string,
  office: PropTypes.string,
};

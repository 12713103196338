import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import Rights from '../../../../../../common/services/Rights';
import { useLookup } from '../../../../../../data/useSelectors';
import { DateTimePickerForm } from '../../../../../components/Form/DateTimePickerForm';
import {
  useLoanDataFieldSelector,
  useLookupOptions,
} from '../../../../../components/Form/utils/customHooks';

const EFFECTIVE_DATE_FORMAT = 'MM/DD/YYYY hh:mm A';
export const EffectiveDate = ({ handleSetLoanDataValue }) => {
  const clientSettings = useLookupOptions('clientsettings') || [];
  const { dataRetentionDays } =
    useLookup('clientSettings', '')?.[0]?.options ?? {};
  const requestAction = useLoanDataFieldSelector('requestAction', 1);

  // TODO: requestAction for relock may change in the near future
  const disableEffectiveDateOnReLock = useMemo(
    () =>
      requestAction === 2 &&
      !clientSettings[0]?.encompassSettings?.changeEffectiveDateOnReLock,
    [requestAction],
  );

  return (
    <DateTimePickerForm
      required={false}
      name="effectiveDate"
      placeholder="MM/DD/YYYY"
      labelText="Effective Date"
      feedbackMessage=""
      pathValue="relockRequest.effectiveDate"
      onChange={({ value, pathValue }) => {
        if (value !== '' && !value.includes('_')) {
          let parsedDate: moment.Moment | null = null;
          parsedDate = moment(value, EFFECTIVE_DATE_FORMAT);
          handleSetLoanDataValue({
            value: (parsedDate as any) === 'Invalid date' ? null : value,
            pathValue,
          });
        }
      }}
      onTimeWheelOpenStartingTime="12:00 AM"
      disabled={disableEffectiveDateOnReLock || !Rights.editEffectiveDate}
      dependencies={dataRetentionDays}
    />
  );
};

EffectiveDate.defaultProps = {
  handleSetLoanDataValue: () => {},
};

EffectiveDate.propTypes = {
  handleSetLoanDataValue: PropTypes.func,
};

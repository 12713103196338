import { DSTooltip } from '@elliemae/ds-tooltip';

const inRange = (a, b, c) => !Number.isNaN(parseFloat(a)) && a >= b && a <= c;

const isShallowEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

const isValueNaN = (v) => {
  const value = typeof v === 'string' ? parseFloat(v.replace(/[$,]/g, '')) : v;
  return Number.isNaN(value) || [false, null, undefined].includes(value);
};

const formatDate = (
  dateString,
  options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  } as const,
) => {
  if (dateString) {
    // TODO we should check if this new date is valid or not
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  }
  return false;
};

const formatTime = (
  dateString,
  options = {
    hour: '2-digit',
    minute: '2-digit',
  } as const,
) => {
  if (dateString) {
    const date = new Date(dateString);
    return date.toLocaleTimeString('en-US', options);
  }
  return false;
};

const safeSum = (...numbers) => {
  let res = 0;
  numbers.forEach((num) => {
    res += Number(num || 0);
  });
  return res;
};

const currency = (v) => {
  const parsedVal = parseFloat(v);
  return Number.isNaN(parsedVal)
    ? ''
    : parsedVal
        .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
        .replace(/\D\d\d$/, '');
};

const currencyWithDecimals = (v, fractions = 0) => {
  const parsedVal = parseFloat(v);
  return Number.isNaN(parsedVal)
    ? ''
    : parsedVal.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: fractions,
      });
};

const formatNumberWithCommas = (value) => {
  if (
    // TODO Number.isNaN("not a number") will return false
    Number.isNaN(value) ||
    value === false ||
    value === null ||
    value === undefined
  )
    return 0;
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const formatAprValue = (p) => {
  if (p === null || p === undefined || Number.isNaN(p)) return '--';
  if (p === false) return '0.000%';
  return `${p.toFixed(3)}%`;
};

const countDecimals = (value) => {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
};

const zeroPad = (num = '') => {
  // TODO we should check this, !Number.isNaN("not a number") returns true
  if (num && !Number.isNaN(num)) {
    const places = 7;
    const parseNum = Number(num).toFixed(2);
    return parseNum.padStart(places, '0');
  }
  return '';
};

const renderConditionalTooltip = (
  tooltipProps,
  condition,
  triggerComponent,
) => {
  let render;

  if (condition) {
    render = (
      <DSTooltip {...tooltipProps} triggerComponent={triggerComponent} />
    );
  } else {
    render = triggerComponent;
  }

  return render;
};

const isEmpty = (value) => {
  return (
    value == null || // From standard.js: Always use === - but obj == null is allowed to check null || undefined
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
};

export const partitionArray = (array, isValid) =>
  array.reduce(
    ([pass, fail], elem) =>
      isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]],
    [[], []],
  );

const focusField = () => {
  // TODO(ngen): I took this method apart for clarity, but it's still not super clear
  // It had flagged multiple types used on the same variable called "input" which was misleading to the user
  document
    .querySelectorAll<HTMLElement>('[data-field-haserror="true"]')?.[0]
    ?.querySelectorAll<HTMLInputElement>('input')?.[0]
    ?.focus();
};

export {
  focusField,
  isValueNaN,
  countDecimals,
  zeroPad,
  formatDate,
  formatTime,
  isShallowEqual,
  currency,
  currencyWithDecimals,
  isEmpty,
  formatNumberWithCommas,
  formatAprValue,
  safeSum,
  inRange,
  renderConditionalTooltip,
};

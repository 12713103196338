/* eslint-disable operator-assignment */
import { isEmpty, isEqual } from 'lodash';
import { safeSum } from '../../../../../common/utils/shared';
import { getFloredValueTextInput } from './helpers';

const calculateWithAmount = (
  amount,
  firstMortgageAmount,
  lienPosition,
  propertyValue,
  secondMortgageAmount,
  totalMortgageAmount,
) => {
  let result;

  switch (amount) {
    case 'base': {
      const mortgageAmount =
        Number(lienPosition) === 2 ? secondMortgageAmount : firstMortgageAmount;
      result = (mortgageAmount || 0) / (propertyValue || 1);
      break;
    }
    case 'total': {
      const mortgageAmount =
        Number(lienPosition) === 2 ? secondMortgageAmount : totalMortgageAmount;
      result = (mortgageAmount || 0) / (propertyValue || 1);
      break;
    }
    default:
      break;
  }
  return result;
};

const calculateHELOC = (loanAmountMaxLine, propertyValue) => {
  return (loanAmountMaxLine || 0) / (propertyValue || 1);
};

export const checkForBaseLoanAmountOnlyScenarios = ({
  lienPosition,
  standardProducts,
  specialProducts,
  loanPurpose,
}) => {
  const VA_IRRRL = 55;
  const FHA = [2];
  const VA = [3];
  const FHAAndVA = FHA.concat(VA);
  const baseLoanAmountPurposes = [1, 4, 5];

  return (
    Number(lienPosition) === 2 ||
    isEqual(standardProducts, FHA) ||
    (isEqual(standardProducts, VA) &&
      baseLoanAmountPurposes.includes(loanPurpose) &&
      isEmpty(specialProducts)) ||
    (isEqual(standardProducts, VA) &&
      specialProducts &&
      specialProducts.includes(VA_IRRRL)) ||
    (standardProducts &&
      standardProducts.every((elem) => FHAAndVA.indexOf(elem) > -1) &&
      loanPurpose === 1 &&
      isEmpty(specialProducts)) ||
    (standardProducts &&
      standardProducts.every((elem) => FHAAndVA.indexOf(elem) > -1) &&
      specialProducts &&
      specialProducts.includes(VA_IRRRL))
  );
};

export const ltvValue = ({ customDependencies }) => {
  let result = 0;

  const {
    firstMortgageAmount,
    isHELOC,
    lienPosition,
    loanAmountMaxLine,
    loanPurpose,
    secondMortgageAmount,
    specialProducts,
    standardProducts,
    totalMortgageAmount,
    value: propertyValue,
  } = customDependencies;

  const loanAmountOnlyScenarios = checkForBaseLoanAmountOnlyScenarios({
    lienPosition,
    standardProducts,
    specialProducts,
    loanPurpose,
  });

  if (propertyValue) {
    if (isHELOC) {
      result = calculateHELOC(loanAmountMaxLine, propertyValue);
    } else {
      const amount = loanAmountOnlyScenarios ? 'base' : 'total';
      result = calculateWithAmount(
        amount,
        firstMortgageAmount,
        lienPosition,
        propertyValue,
        secondMortgageAmount,
        totalMortgageAmount,
      );
    }
  }

  if (result === Infinity) return '0.000';

  if (result > 999.999) return '999.999';

  return (result * 100).toFixed(3);
};

export const cltvValue = ({ customDependencies }) => {
  let result = 0;

  const {
    isHELOC,
    isGovie,
    isIRRRL,
    isVA,
    firstMortgageAmount,
    lienPosition,
    loanAmountMaxLine,
    loanPurpose,
    secondMortgageAmount,
    totalMortgageAmount,
    otherHELOCDraws,
    otherHELOCLines,
    totSubClosedEndMort,
    value: propertyValue,
  } = customDependencies;

  if (isHELOC) {
    if (lienPosition === 1) {
      result = safeSum(
        secondMortgageAmount,
        totalMortgageAmount,
        otherHELOCDraws,
      );
    }
    if (lienPosition === 2) {
      result = safeSum(
        firstMortgageAmount,
        totSubClosedEndMort,
        secondMortgageAmount,
        otherHELOCDraws,
      );
    }
  } else if (isGovie) {
    if (lienPosition === 1) {
      if (isVA && (loanPurpose === 2 || loanPurpose === 3) && !isIRRRL) {
        result = safeSum(
          totalMortgageAmount,
          secondMortgageAmount,
          otherHELOCLines,
        );
      } else {
        result = safeSum(
          firstMortgageAmount,
          secondMortgageAmount,
          otherHELOCLines,
        );
      }
    }
    if (lienPosition === 2) {
      result = safeSum(
        firstMortgageAmount,
        totSubClosedEndMort,
        secondMortgageAmount,
        loanAmountMaxLine,
        otherHELOCLines,
      );
    }
  } else {
    // Is conventional
    if (lienPosition === 1) {
      result = safeSum(
        totalMortgageAmount,
        secondMortgageAmount,
        otherHELOCDraws,
      );
    }
    if (lienPosition === 2) {
      result = safeSum(
        firstMortgageAmount,
        totSubClosedEndMort,
        secondMortgageAmount,
        otherHELOCDraws,
      );
    }
  }
  result = result / (Number(propertyValue) || 1);
  if (result === Infinity) {
    return '0.000';
  }

  return (result * 100).toFixed(3);
};

export const hcltvValue = ({ customDependencies }) => {
  let result = 0;

  const {
    isHELOC,
    firstMortgageAmount,
    lienPosition,
    totSubClosedEndMort,
    loanAmountMaxLine,
    otherHELOCLines,
    secondMortgageAmount,
    value: propertyValue,
  } = customDependencies;

  if (isHELOC && lienPosition === 1) {
    result = safeSum(secondMortgageAmount, loanAmountMaxLine, otherHELOCLines);
  } else if (isHELOC && lienPosition === 2) {
    result = safeSum(
      firstMortgageAmount,
      totSubClosedEndMort,
      loanAmountMaxLine,
      otherHELOCLines,
    );
  } else if (!isHELOC && lienPosition === 2) {
    result = safeSum(
      firstMortgageAmount,
      totSubClosedEndMort,
      secondMortgageAmount,
      loanAmountMaxLine,
      otherHELOCLines,
    );
  }

  result = result / (Number(propertyValue) || 1);
  if (result === Infinity) {
    return '0.000';
  }

  return (result * 100).toFixed(3);
};

export const loanAmountValue = ({ value, customDependencies }) => {
  const {
    value: propertyValue,
    firstMortgageAmount,
    ...data
  } = customDependencies;
  let result = value;
  if (data.productType) {
    const loanAmountOnlyScenarios =
      checkForBaseLoanAmountOnlyScenarios(customDependencies);
    if (data?.isHELOC) {
      result = ((parseFloat(value || '0') || 0) * (propertyValue || 1)) / 100;
      result = result.toFixed(3);
    } else {
      if (loanAmountOnlyScenarios) {
        result = ((parseFloat(value || '0') || 0) * (propertyValue || 1)) / 100;
      } else {
        result =
          ((parseFloat(value || '0') || 0) * (propertyValue || 1)) / 100 -
          (data.financedAmount || 0);
      }
      result = result.toFixed(3);
    }
  }
  return getFloredValueTextInput(result);
};

export const calculateLoanAmountMaxLine = ({ value, customDependencies }) => {
  const { value: propertyValue } = customDependencies;
  const result = ((parseFloat(value || '0') || 0) * (propertyValue || 1)) / 100;
  return result;
};

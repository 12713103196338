/* eslint-disable no-console */
import { BUTTON_VARIANT } from '@elliemae/ds-button';
import { InfoCircle } from '@elliemae/ds-icons';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Common from '../../../../common/services/Common';
import Session, {
  ALL_SUMMARY_PARAMS,
  APPLICATION_NAME,
  IS_BUYSIDE,
  IS_LO,
} from '../../../../common/services/Session';
import { isEmpty } from '../../../../common/utils/shared';
import {
  useClientSettingsDataSelector,
  useLoanDataFieldSelector,
  useLoanDataSelector,
  useLoanInformationField,
  useLockDeskStatusActionsSelector,
  useOriginalLoanInformationSelector,
  useOriginalLoanLockRequests,
  usePersonaAllowedActionsSelector,
  useRateSelector,
  useSelectedProductSelector,
} from '../../../../data/useSelectors';
import { FooterButtons } from '../../../components/FooterButtons';

import {
  setLockDeskModal,
  setLockRequestUnderOnrp,
} from '../../../../data/lockDeskStatus/actions';
import {
  floatPricingAction,
  requestLockAction,
  saveLoanAction,
} from '../../../../data/lockSummary/actionCreators';
import { AppDispatch } from '../../../../store';
import { LockRequestedModal } from '../LockRequestedModal';
import { RateAlert } from '../RateAlertModal/RateAlertContainer';
import {
  checkLockRequested,
  getEPCActionType,
  getRequestLockActionType,
  isFloatButtonDisabled,
  isFloatButtonVisible,
  isRequestLockButtonDisabled,
  isRequestLockButtonVisible,
  isSaveToLoanButtonDisabled,
  isSaveToLoanButtonVisible,
} from './utils';

export const FooterActionButtons = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loanData = useLoanDataSelector();
  const rateSelector = useRateSelector();
  const clientDataSettingsSelector = useClientSettingsDataSelector();
  const allowedActions = usePersonaAllowedActionsSelector();
  const { isDisableRequestLockButton, isAllowLockUpdate } =
    useLockDeskStatusActionsSelector();
  const applicationName = Session.get(APPLICATION_NAME);
  const { loan: { noClosingCost, lenderFeeWaiver, autolocked } = {} } =
    rateSelector as any;
  const { lien: { disableLock } = {} } = rateSelector as any;
  const { tpo } = useOriginalLoanInformationSelector() as any;
  const { rateStale } = useSelectedProductSelector();
  const lockRequests = useOriginalLoanLockRequests();
  const [isRequestedModalOpen, setRequestedModalOpen] = useState(false);
  const [isRateAlertOpen, setIsRateAlertOpen] = useState(false);
  const firstMortgageAmount = useLoanInformationField('firstMortgageAmount', 0);
  const loanChannel = useLoanDataFieldSelector(
    'loanInformation.loanChannel',
  ) as unknown as number;
  const isValidChannel = [0, 1, 2, 4].includes(loanChannel);
  const isBuySide = Session.get(IS_BUYSIDE);
  const { relockRequest } = loanData as any;
  const { specificProgramID } = relockRequest;
  const isVisibleLockDesk = isValidChannel && !isBuySide;
  const disabledLockHoverMsg =
    'Locking this Loan Program has been disabled by your Administrator.';

  const handleSaveLoanAction = async () => {
    await dispatch(
      saveLoanAction({
        noClosingCost,
        lenderFeeWaiver,
      }),
    );
  };

  const handleFloatAction = async () => {
    await dispatch(
      floatPricingAction({
        noClosingCostOption: noClosingCost,
        lenderFeeWaiverOption: lenderFeeWaiver,
      }),
    );
  };

  const shutDownAllowLockRequestValidation = () => {
    let isLockRequestDisable = false;
    if (isVisibleLockDesk && isAllowLockUpdate) {
      if (specificProgramID === null) {
        isLockRequestDisable = true;
      }
      if (isEmpty(Session.get(ALL_SUMMARY_PARAMS))) {
        return isLockRequestDisable;
      }
      const selectedProductData = Session.get(ALL_SUMMARY_PARAMS);
      const { programId } = selectedProductData || {};
      if (specificProgramID !== programId) {
        isLockRequestDisable = true;
      }
    }
    return isLockRequestDisable;
  };

  const handleRequestLockAction = async () => {
    const requestLockActionType = getRequestLockActionType(loanData);
    const EPCActionType = getEPCActionType(requestLockActionType, autolocked);
    setRequestedModalOpen(false);

    await dispatch(
      requestLockAction(EPCActionType, requestLockActionType, {
        noClosingCost,
        lenderFeeWaiver,
      }),
    );
  };

  const handleRateAlertAction = () => {
    setIsRateAlertOpen(true);
  };

  const handleRateAlertCancel = () => {
    setIsRateAlertOpen(false);
  };

  const fetchLockDeskValidateStatus = async () => {
    let isEligible = true;
    const { programId } = Session.get(ALL_SUMMARY_PARAMS) || {};
    const { requestLockStatus, requestAction } = loanData as any;
    const currentChannel = loanChannel === 0 ? 1 : loanChannel;
    try {
      if (isVisibleLockDesk) {
        const response = await Common.getLockDeskValidateData({
          loanChannelId: currentChannel,
          loanAmount: firstMortgageAmount,
          previousProgramId: specificProgramID,
          currentProgramId: programId,
          requestActionType: requestAction,
          requestLockStatus,
        });
        isEligible = response.eligible;
        if (response?.isLockRequestUnderOnrp) {
          dispatch(setLockRequestUnderOnrp(true));
        }
      }
    } catch (error) {
      console.log(error);
    }
    return isEligible;
  };

  const handleRequestLockClick = async () => {
    const isLockRequested = checkLockRequested(loanData, lockRequests);
    if (isLockRequested) setRequestedModalOpen(true);
    else handleRequestLockAction();
  };

  const requestLockButtonDisabled = isRequestLockButtonDisabled(
    rateStale,
    loanData,
    applicationName,
    allowedActions,
    isDisableRequestLockButton,
    shutDownAllowLockRequestValidation,
    disableLock,
  );

  const floatButtonVisible = isFloatButtonVisible(
    allowedActions,
    clientDataSettingsSelector,
  );
  const floatButtonDisabled = isFloatButtonDisabled(allowedActions);

  const saveToLoanButtonDisabled = isSaveToLoanButtonDisabled(
    loanData,
    applicationName,
    allowedActions,
    disableLock,
  );

  const saveToLoanButtonVisible = isSaveToLoanButtonVisible(
    applicationName,
    allowedActions,
    tpo,
    clientDataSettingsSelector,
  );

  const requestLockButtonVisible = isRequestLockButtonVisible(
    applicationName,
    loanData,
    allowedActions,
  );

  return (
    Session.get(IS_LO) && (
      <>
        <LockRequestedModal
          isModalOpen={isRequestedModalOpen}
          handleRequest={handleRequestLockAction}
          handleCancel={() => setRequestedModalOpen(false)}
        />
        <RateAlert
          isOpen={isRateAlertOpen}
          handleCancel={handleRateAlertCancel}
        />
        <FooterButtons
          data-testid="FooterActionButtonsLock-all-components"
          buttons={[
            {
              type: 'secondary',
              text: 'Rate Alert',
              // TODO: Update onClick action with EPPS-73039
              onClick: handleRateAlertAction,
              variant: BUTTON_VARIANT.DEFAULT,
            },
            {
              type: 'secondary',
              text: 'Float',
              onClick: handleFloatAction,
              variant: BUTTON_VARIANT.DEFAULT,
              visible: floatButtonVisible,
              disabled: floatButtonDisabled,
            },
            {
              type: 'secondary',
              text: 'Update Lock Request Form',
              onClick: handleSaveLoanAction,
              variant: BUTTON_VARIANT.DEFAULT,
              disabled: saveToLoanButtonDisabled,
              visible: saveToLoanButtonVisible,
              tooltipText: disableLock ? disabledLockHoverMsg : null,
            },
            {
              type: 'primary',
              text: 'Request Lock',
              onClick: handleRequestLockClick,
              variant: requestLockButtonDisabled
                ? BUTTON_VARIANT.DISABLED
                : BUTTON_VARIANT.DEFAULT,
              disabled: requestLockButtonDisabled,
              visible: requestLockButtonVisible,
              tooltipText: disableLock ? disabledLockHoverMsg : null,
            },
          ]}
          tooltip={
            isVisibleLockDesk ? (
              <InfoCircle
                color={['brand-primary', '600']}
                onClick={() => dispatch(setLockDeskModal(true))}
              />
            ) : undefined
          }
        />
      </>
    )
  );
};

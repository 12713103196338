import { DSSeparator } from '@elliemae/ds-separator';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataProvider } from '../../common/utils/customHooks/useAppData';
import { isShallowEqual } from '../../common/utils/shared';
import {
  getClientSettingSelector,
  getRateSelectorAction,
} from '../../data/lockSummary/actionCreators';
import { closeLoader, openLoader } from '../../data/screenLoader/actions';
import {
  StyledEppsContainerBody,
  StyledEppsParentView,
  StyledEppsWrapper,
} from '../global/styles/styled';
import { CommentsSection } from './components/CommentsSection';
import { DiffElements } from './components/DiffElements';
import { FooterActionButtons } from './components/FooterActionButtons';
import { GeneralLoanInformation } from './components/GeneralLoanInformation/GeneralLoanInformation';
import { InformationGroupSection } from './components/InformationGroupSection';
import { getTableDataWithIDs } from './components/PricingDetailsModal/helpers';
import { PricingTable } from './components/PricingTable';
import { SummaryHeader } from './components/SummaryHeader';
import { StatusFailMessage } from './components/SummaryHeader/StatusFailMessage';
import { AppDispatch } from '../../store';

export const LockSummary = () => {
  const [dataTableContext, setTableDataContext] = useState<any>({
    rawPrice: [],
    adjustedPrice: [],
  });
  const dispatch = useDispatch<AppDispatch>();
  const priceData = useSelector(
    ({
      epps: {
        lockSummary: { rateSelector },
      },
    }) => rateSelector.priceData,
    isShallowEqual,
  );

  useEffect(() => {
    // TODO This code snippet was changed and needs to be refactored.
    if (priceData) setTableDataContext(getTableDataWithIDs(priceData));
  }, [priceData]);

  const initPage = async () => {
    dispatch(openLoader('Loading'));
    await Promise.all([
      dispatch(getRateSelectorAction()),
      dispatch(getClientSettingSelector()),
    ]);
    dispatch(closeLoader());
  };

  useEffect(() => {
    initPage();
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledEppsParentView>
      <StatusFailMessage />
      <StyledEppsContainerBody>
        <StyledEppsWrapper>
          <DataProvider
            value={{
              dataTableContext,
              setTableDataContext,
            }}
          >
            <SummaryHeader />
            <GeneralLoanInformation />
            <DSSeparator
              margin="s"
              type="non-form"
              orientation="horizontal"
              position="initial"
            />
            <PricingTable />
            <InformationGroupSection />
            <CommentsSection />
          </DataProvider>
        </StyledEppsWrapper>
      </StyledEppsContainerBody>
      <FooterActionButtons />
      <DiffElements />
    </StyledEppsParentView>
  );
};

import { put, select, takeLatest } from 'redux-saga/effects';
import {
  loanDataFieldSelector,
  lookupsSelector,
  searchFormSelector,
} from '../selectors';
import { searchFormActions } from './index';
import { initialDepends } from './utils/constants';
import { formValidations, parseInitialValues } from './utils/helpers';

const { setFormValidations, getFormValidationsAction } = searchFormActions;

// ----- WORKERS ------

// TODO(ngen): wrapping all sagas in try/catch as required by linter. This, and other files in /app/store

/* Getting the initial values from the store and setting them to the form. */
function* getInitialValues(initialValues = {}) {
  try {
    const formInitialValues = yield select(searchFormSelector('initialValues'));
    if (Object.keys(formInitialValues).length) {
      const parsingInitialValueResult = parseInitialValues(initialValues);
      yield put(searchFormActions.setInitialValues(parsingInitialValueResult));
    }
  } catch (e) {}
}

/* Getting field validation to set a flow in the form and being able to display or hide some fields. */
function* getFormValidation() {
  try {
    const lookupsLoanTerms = yield select(lookupsSelector('loanTerms'));
    const initialValues = yield select(loanDataFieldSelector(initialDepends));
    const validations = formValidations({ lookupsLoanTerms, ...initialValues });
    yield getInitialValues(initialValues);
    yield put(setFormValidations(validations));
  } catch (e) {}
}

// ----- WATCHERS ------

/* A watcher function that is listening to for the action `getFormValidationsAction` to be dispatched. */
export default function* watchers() {
  yield takeLatest(getFormValidationsAction.type, getFormValidation);
}

/* eslint-disable @typescript-eslint/default-param-last */
import { getAppConfigValue } from '@elliemae/pui-app-sdk';
import { v4 as uuidv4 } from 'uuid';
import Common from '../../../common/services/Common';
import Rights from '../../../common/services/Rights';
import Session, { IS_SELLSIDE } from '../../../common/services/Session';
import { formatDate } from '../../../common/services/helpers';
import { isEmpty, partitionArray, safeSum } from '../../../common/utils/shared';

const sectionTitle = (description, editable = false, handlerName) => ({
  id: uuidv4(),
  description,
  editable,
  type: 'groupTitle',
  handlerName,
});

// TODO: check if p false is a valid scenario
const formatPriceValue = (p) => {
  if (p === null || p === undefined || Number.isNaN(p)) return '';
  if (p === false) return '0.000';
  return Number(p).toFixed(3);
};

const formatPriceValuePercent = (p) => {
  if (p === null || p === undefined || Number.isNaN(p)) return '';
  if (p === false) return '0.000%';
  return `${Number(p).toFixed(3)}%`;
};

const parseAdjustment = (adjustment) => {
  return {
    ...adjustment,
    rate: formatPriceValuePercent(adjustment.rate),
    price: formatPriceValue(adjustment.price),
    currentRate: formatPriceValuePercent(adjustment.currentRate),
    currentPrice: formatPriceValue(adjustment.currentPrice),
    change: formatPriceValue(adjustment.change),
    type: 'regular',
    id: adjustment.id || uuidv4(),
  };
};

const formatAdjustmentsOrConcessions = (
  adjustments,
  isValidate,
  rowsBeforeEdit,
  isConcession = false,
  pricingHasChanged,
) =>
  adjustments.map((adj) => {
    const newAdj = {
      ...adj,
      id: adj.id || uuidv4(),
      type: 'regular',
      currentPrice: formatPriceValue(adj.price),
      price: formatPriceValue(adj.price),
      rate: formatPriceValuePercent(adj.rate),
      approvalDate: formatDate(adj.approvalDate, 'Calendar'),
    };
    if (
      pricingHasChanged &&
      isValidate &&
      !isEmpty(rowsBeforeEdit) &&
      newAdj.adjustmentType
    ) {
      // this if checks if there is two tables and then we store the old Final price
      const oldPrice = rowsBeforeEdit.find(
        (oldAdj) =>
          oldAdj.id === newAdj.id ||
          (oldAdj.adjustmentType === newAdj.adjustmentType &&
            oldAdj.description === newAdj.description),
      )?.price;
      newAdj.price = formatPriceValue(oldPrice) || '-';
    }
    if (!pricingHasChanged) {
      newAdj.description = adj.description;
    }
    if (isConcession) {
      newAdj.description = formatDate(adj.approvalDate, 'Calendar');
    }
    return newAdj;
  });

const calculateVariationsTotal = (loanVariations: any, key = 'price') => {
  return []
    .concat(...(Object.values(loanVariations) as any))
    .reduce(
      (sum, loanVariation) =>
        sum + convertStrPercentToNumber(loanVariation[key]),
      0,
    );
};

const convertStrPercentToNumber = (str) => {
  if (!str) return 0;
  if (typeof str === 'number') return str;
  return Number(str.replace('%', ''));
};

const emptyAdj = {
  price: null,
  approvalDate: '-',
  description: '-',
};

export const generateAdjustments = (
  adjustments: {
    adjustmentType: string;
    description: string;
    price: string;
    rate: string;
    currentPrice: string;
    currentRate: string;
    change: string;
    id: string;
  }[] = [],
  loanVariations: any = {},
  isBuyside: any = false,
  rowsBeforeEdit: {
    adjustmentType: string;
    description: string;
    price: string;
    rate: string;
    currentPrice: string;
    currentRate: string;
    change: string;
    id: string;
  }[] = [],
  isValidate: any = false,
  pricingHasChanged?: any,
) => {
  let {
    LockExtensionAdjustment: lockExtensions,
    ReLockFeeAdjustment: reLockFees,
    CustomPriceAdjustment: customPriceAdjustments,
    CorporatePriceConcession,
    BranchPriceConcession,
  } = loanVariations as any;

  lockExtensions = (lockExtensions || []).concat(
    (adjustments ?? []).filter(
      (adj) => adj.adjustmentType === 'LockExtensionAdjustment',
    ),
  );
  reLockFees = (reLockFees || []).concat(
    (adjustments ?? []).filter(
      (adj) => adj.adjustmentType === 'ReLockFeeAdjustment',
    ),
  );
  customPriceAdjustments = (customPriceAdjustments || []).concat(
    (adjustments ?? []).filter(
      (adj) => adj.adjustmentType === 'CustomPriceAdjustment',
    ),
  );
  CorporatePriceConcession = (CorporatePriceConcession || []).concat(
    (adjustments ?? []).filter(
      (adj) => adj.adjustmentType === 'CorporatePriceConcession',
    ),
  );
  BranchPriceConcession = (BranchPriceConcession || []).concat(
    (adjustments ?? []).filter(
      (adj) => adj.adjustmentType === 'BranchPriceConcession',
    ),
  );

  let oldLoanVariations;
  let oldExtraPrice;
  let oldExtraRate;
  if (isValidate && !isEmpty(rowsBeforeEdit)) {
    oldLoanVariations = {
      LockExtensionAdjustment:
        rowsBeforeEdit.find(
          (adj) => adj.adjustmentType === 'LockExtensionAdjustment',
        ) || [],
      ReLockFeeAdjustment:
        rowsBeforeEdit.find(
          (adj) => adj.adjustmentType === 'ReLockFeeAdjustment',
        ) || [],
      CustomPriceAdjustment:
        rowsBeforeEdit.find(
          (adj) => adj.adjustmentType === 'CustomPriceAdjustment',
        ) || [],
      CorporatePriceConcession:
        rowsBeforeEdit.find(
          (adj) => adj.adjustmentType === 'CorporatePriceConcession',
        ) || [],
      BranchPriceConcession:
        rowsBeforeEdit.find(
          (adj) => adj.adjustmentType === 'BranchPriceConcession',
        ) || [],
    };
    // needed to affect final price of "proposed price" columns
    oldExtraPrice = calculateVariationsTotal(oldLoanVariations, 'price');
    oldExtraRate = calculateVariationsTotal(oldLoanVariations, 'rate');
  }
  const extraPrice = calculateVariationsTotal(
    {
      LockExtensionAdjustment: lockExtensions,
      ReLockFeeAdjustment: reLockFees,
      CustomPriceAdjustment: customPriceAdjustments,
      CorporatePriceConcession,
      BranchPriceConcession,
    },
    'price',
  );
  const extraRate = calculateVariationsTotal(
    {
      LockExtensionAdjustment: lockExtensions,
      ReLockFeeAdjustment: reLockFees,
      CustomPriceAdjustment: customPriceAdjustments,
      CorporatePriceConcession,
      BranchPriceConcession,
    },
    'rate',
  );

  // Filter Adjustments not allowed for the rows
  let filteredAdjustments = adjustments.map((adj) => parseAdjustment(adj));
  let adjsProfitMarginRows = [];
  const isSellSide = Session.get(IS_SELLSIDE);
  const roles = Common.getUserRoles() || [];
  const viewHoldBackRole = 12;
  const viewLOCompRole = 20;
  const hasViewHoldBackRights = roles.some(
    ({ roleId }) => Number(roleId) === viewHoldBackRole,
  );
  const hasLOCompViewRight = roles.some(
    ({ roleId }) => Number(roleId) === viewLOCompRole,
  );

  [filteredAdjustments, adjsProfitMarginRows] = partitionArray(
    filteredAdjustments,
    ({ adjustmentType }) =>
      adjustmentType !== 'Holdback' && adjustmentType !== 'Profit',
  );

  if (!hasViewHoldBackRights && !hasLOCompViewRight) {
    filteredAdjustments = filteredAdjustments.filter(
      (adjustment) => adjustment.description !== 'L.O. Compensation',
    );
  }

  adjsProfitMarginRows = adjsProfitMarginRows.filter(({ margin }) => !margin);

  // Find the final price
  const getFinalPrice = filteredAdjustments.find(
    (el) => el.adjustmentType === 'Final',
  );

  const currentPrice =
    getFinalPrice?.currentPrice &&
    safeSum(Number(getFinalPrice.currentPrice), oldExtraPrice, extraPrice);

  const price =
    getFinalPrice &&
    formatPriceValue(
      safeSum(
        Number(getFinalPrice.price),
        isValidate && pricingHasChanged ? 0 : extraPrice,
      ),
    );

  const currentRate =
    getFinalPrice?.currentRate &&
    safeSum(Number(getFinalPrice.currentRate), oldExtraRate, extraRate);

  const rate =
    getFinalPrice &&
    formatPriceValuePercent(
      safeSum(
        convertStrPercentToNumber(getFinalPrice.rate),
        isValidate && pricingHasChanged ? 0 : extraRate,
      ),
    );

  const finalPrice = getFinalPrice && {
    ...getFinalPrice,
    description: 'FINAL PRICE',
    price,
    rate,
    ...(getFinalPrice.currentPrice ? { currentPrice } : {}),
    ...(getFinalPrice.currentRate ? { currentRate } : {}),
  };
  const getBasePrice = filteredAdjustments.find(
    (el) => el.adjustmentType === 'Base',
  );
  const basePrice = getBasePrice && {
    ...getBasePrice,
    description: 'Base Price',
  };
  const adjustmentTypesBlacklist = [
    'Base',
    'Final',
    'LockExtensionAdjustment',
    'ReLockFeeAdjustment',
    'CustomPriceAdjustment',
    'CorporatePriceConcession',
    'BranchPriceConcession',
  ];
  const adjustmentRows = filteredAdjustments.filter(
    (el) => !adjustmentTypesBlacklist.includes(el.adjustmentType) && !el.margin,
  );

  const emptyAdjArr = isBuyside ? [] : [emptyAdj];
  if (getAppConfigValue<boolean>('partner-ui.showPricingDetails')) {
    return [
      ...(basePrice ? [basePrice] : []),
      sectionTitle('Adjustments'),
      ...adjustmentRows,
      ...[
        ...(Rights.viewProfitMargin && !isSellSide
          ? [sectionTitle('Profitability'), ...adjsProfitMarginRows]
          : []),
        sectionTitle('Lock Extensions'),
        ...formatAdjustmentsOrConcessions(
          lockExtensions || emptyAdjArr,
          isValidate,
          rowsBeforeEdit,
          false,
          pricingHasChanged,
        ),
        sectionTitle(
          'Re-Lock Fees',
          Rights.editRelockFee && Rights.accessBuyside,
          'ReLockFeeAdjustment',
        ),
        ...formatAdjustmentsOrConcessions(
          reLockFees || emptyAdjArr,
          isValidate,
          rowsBeforeEdit,
          false,
          pricingHasChanged,
        ),
        sectionTitle(
          'Custom Adjustments',
          Rights.editCustomAdjustments && Rights.accessBuyside,
          'CustomPriceAdjustment',
        ),
        ...formatAdjustmentsOrConcessions(
          customPriceAdjustments || emptyAdjArr,
          isValidate,
          rowsBeforeEdit,
          false,
          pricingHasChanged,
        ),
        ...(!isSellSide
          ? [
              sectionTitle(
                'Corporate Price Concessions',
                Rights.editConcessions && Rights.accessBuyside,
                'CorporatePriceConcession',
              ),
              ...formatAdjustmentsOrConcessions(
                CorporatePriceConcession || emptyAdjArr,
                isValidate,
                rowsBeforeEdit,
                true,
                pricingHasChanged,
              ),
              sectionTitle(
                'Branch Price Concessions',
                Rights.editConcessions && Rights.accessBuyside,
                'BranchPriceConcession',
              ),
              ...formatAdjustmentsOrConcessions(
                BranchPriceConcession || emptyAdjArr,
                isValidate,
                rowsBeforeEdit,
                true,
                pricingHasChanged,
              ),
            ]
          : []),
      ],
      ...(finalPrice ? [finalPrice] : []),
    ];
  }
  return [
    ...(basePrice ? [basePrice] : []),
    sectionTitle('Adjustments'),
    ...adjustmentRows,
    ...[
      ...(Rights.viewProfitMargin
        ? [sectionTitle('Profitability'), ...adjsProfitMarginRows]
        : []),
    ],
    ...(finalPrice ? [finalPrice] : []),
  ];
};

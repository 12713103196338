import { DSAccordion, DSAccordionItem } from '@elliemae/ds-accordion';
import { BANNER_TYPES, DSBanner } from '@elliemae/ds-banner';
import { Grid, GridContainer, GridItem } from '@elliemae/ds-grid';
import { DSHeader } from '@elliemae/ds-header';
import { DSSeparator } from '@elliemae/ds-separator';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Session, { IS_LO } from '../../../../common/services/Session';
import { setLoanDataValue } from '../../../components/Form/utils/common';
import { ComboBoxForm, ControlledCheckboxForm } from '../../../components/Form';
import { useLoanDataFieldSelector } from '../../../components/Form/utils/customHooks';
import { AverageRepresentativeCreditScoreField } from './components/AverageRepresentativeCreditScoreField';
import { BorrowersTable } from './components/Borrowers/BorrowersTable';
import { CreditScoreField } from './components/CreditScoreField';
import { Delinquencies } from './components/Delinquencies';
import { DocumentationTypes } from './components/DocumentationTypes';
import { SupplementalFunding } from './components/SupplementalFunding';
import * as dependencies from './utils/dependencies';
import { sortByDisplayOrder } from './utils/helpers';

export const FinanceInformationForm = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(null);
  const borrowers: any[] =
    (useLoanDataFieldSelector('borrowers') as any).value || [];
  const originalBorrowersLength = useMemo(() => borrowers.length, []);
  const isIntegrationMode = Session.get(IS_LO);
  const updatedRows = borrowers.map((row) => ({
    ...row,
    fullName: `${row.firstName || ''} ${row.lastName || ''}`.trim(),
  }));
  const lienPosition = (
    useLoanDataFieldSelector('loanInformation.lienPosition') as any
  ).value;
  const loanPurpose = (
    useLoanDataFieldSelector('loanInformation.loanPurpose') as any
  ).value;

  const handleSetLoanDataValue = ({ value, pathValue }) => {
    const parseValue = ['', undefined, 'none'].includes(value) ? null : value;
    dispatch(setLoanDataValue(parseValue, pathValue) as any);
  };

  const showSupplementalFunding =
    lienPosition !== 2 && ![2, 3].includes(loanPurpose);
  return (
    <>
      <DSSeparator
        dashed
        mb="16px"
        mt="8px"
        orientation="horizontal"
        type="non-form"
        position="initial"
      />
      <DSHeader
        text="Finance Information"
        fontSize={16}
        fontWeight="bold"
        lineHeight={1}
        mb="xs"
      />
      <Grid gutter="xs" alignItems="flex-start">
        <Grid alignItems="flex-start" cols={['1fr 1fr 1fr 1fr']} gutter="xs">
          <DocumentationTypes />
        </Grid>
        <Grid alignItems="flex-start" cols={['1fr 1fr 1fr 1fr']} gutter="xs">
          <CreditScoreField />
          <AverageRepresentativeCreditScoreField />
          <Grid alignItems="center" pt={20}>
            <ControlledCheckboxForm
              label="No Credit Score"
              name="noCreditScores"
              pathValue="borrowerFinancialHistory.noCreditScores"
              onChange={handleSetLoanDataValue}
            />
          </Grid>
        </Grid>
        <Grid alignItems="flex-start" cols={['1fr 1fr 1fr 1fr']} gutter="xs">
          <Grid alignItems="center" pt={20}>
            <ControlledCheckboxForm
              name="firstTimeHomeBuyers"
              label="First Time Home Buyer"
              pathValue="borrowerFinancialHistory.firstTimeHomeBuyers"
              onChange={handleSetLoanDataValue}
              extraDependencies={dependencies.EXTRA_FIRST_TIME_HOME_BUYER}
              customValue={({ value, pathValue, customDependencies }) => {
                const { isRefinance } = customDependencies;
                if (isRefinance) {
                  handleSetLoanDataValue({ value: false, pathValue });
                  return false;
                }
                return value;
              }}
              customDisabled={({ customDependencies }) => {
                const { isRefinance } = customDependencies;
                return isRefinance;
              }}
            />
          </Grid>
          <Grid alignItems="center" pt={20}>
            <ControlledCheckboxForm
              name="demonstrateHousingPaymentHistory"
              pathValue="borrowerFinancialHistory.demonstrateHousingPaymentHistory"
              label="Borrower can demonstrate a 12-month mortgage/rental history"
              onChange={handleSetLoanDataValue}
            />
          </Grid>
        </Grid>
        <Grid>
          <DSSeparator
            dashed
            margin="none"
            orientation="horizontal"
            position="initial"
          />
        </Grid>
        <DSAccordion activeValue={active} onChange={setActive}>
          <DSAccordionItem
            title={active === 0 ? 'Hide Detailed Entry' : 'Open Detailed Entry'}
            key={0}
            value={0}
          >
            <GridContainer
              cols={8}
              alignItems="center"
              style={{ marginBottom: 8 }}
            >
              <GridItem span={isIntegrationMode ? 8 : 7}>
                <DSHeader
                  text="Borrower Assets, Debt and Income"
                  fontSize={12}
                  fontWeight="bold"
                  lineHeight={1}
                />
              </GridItem>
            </GridContainer>
            <DSBanner
              body="Any assets or liabilities added to the borrower table below will need to be manually added to your loan origination system."
              isOpen={originalBorrowersLength !== borrowers.length}
              label="Borrower Assets and Liabilities"
              type={BANNER_TYPES.WARNING}
              showCloseButton={false}
            />
            <BorrowersTable
              rows={updatedRows}
              isIntegrationMode={isIntegrationMode}
              handleSetLoanDataValue={handleSetLoanDataValue}
            />
            <Grid>
              <DSSeparator
                dashed
                margin="none"
                orientation="horizontal"
                position="initial"
              />
            </Grid>

            <Grid cols={['16fr', '1fr', '16fr']}>
              <Grid>
                <DSHeader
                  text="Mortgage  Deliquency"
                  fontSize={12}
                  fontWeight="bold"
                  lineHeight={1}
                  style={{ marginTop: '16px' }}
                />
                <Delinquencies
                  pathValue="borrowerFinancialHistory"
                  onChange={({ value, pathValue, newKeyValue }) => {
                    handleSetLoanDataValue({
                      value: {
                        ...value,
                        [newKeyValue.key]: newKeyValue.value,
                      },
                      pathValue,
                    });
                  }}
                />
              </Grid>
              {/* TODO(NGEN): <grid> below was mispelled. Would have either not rendered the DSSeparator, or rendered with a non binded element surrounding it */}
              <Grid>
                <DSSeparator
                  dashed
                  margin="s"
                  position="initial"
                  orientation="vertical"
                  lineHeight={1}
                  style={{ justifyContent: 'center', marginTop: '10px' }}
                />
              </Grid>
              <Grid cols={['1fr', '1fr']} gutter="xxs">
                <DSHeader
                  text="Default and Bankruptcy"
                  fontSize={12}
                  fontWeight="bold"
                  lineHeight={1}
                  style={{ marginTop: '16px' }}
                />
                <DSHeader
                  text=""
                  fontSize={12}
                  fontWeight="bold"
                  lineHeight={1}
                  style={{ marginTop: '16px' }}
                />
                <ComboBoxForm
                  isNonClearable
                  name="foreclosure"
                  placeholder="Select Income"
                  label="Notice of Default (NOD)"
                  pathValue="borrowerFinancialHistory.noticeOfDefaultForeClosure"
                  customValue={({ value }) => (value === null ? 'none' : value)}
                  initialValue="none"
                  optionsFilter={sortByDisplayOrder}
                  onChangeV2={handleSetLoanDataValue}
                />

                <ComboBoxForm
                  isNonClearable
                  name="nodTypes"
                  placeholder="Type"
                  label="Type"
                  pathValue="borrowerFinancialHistory.nodType"
                  dependencies={dependencies.NOD_TYPE}
                  customValue={({ value, customDependencies }) => {
                    const { noticeOfDefaultForeClosure } = customDependencies;
                    return noticeOfDefaultForeClosure ? value : 1;
                  }}
                  initialValue={1}
                  customDisabled={({ customDependencies }) => {
                    const { noticeOfDefaultForeClosure } = customDependencies;
                    return [null, 'null'].includes(noticeOfDefaultForeClosure);
                  }}
                  onChangeV2={handleSetLoanDataValue}
                />

                <ComboBoxForm
                  isNonClearable
                  name="bankruptcy"
                  placeholder="Select Bankruptcy"
                  label="Bankruptcy"
                  pathValue="borrowerFinancialHistory.bankruptcyInMonths"
                  customValue={({ value }) => (value === null ? 'none' : value)}
                  optionsFilter={sortByDisplayOrder}
                  onChangeV2={handleSetLoanDataValue}
                />
                <ComboBoxForm
                  isNonClearable
                  name="bankruptcyType"
                  placeholder="Type"
                  label="Type"
                  pathValue="borrowerFinancialHistory.bkType"
                  dependencies={dependencies.BANKRUPCY_TYPE}
                  customValue={({ value, customDependencies }) => {
                    const { bankruptcyInMonths } = customDependencies;
                    return bankruptcyInMonths ? value : 1;
                  }}
                  initialValue={1}
                  customDisabled={({ customDependencies }) => {
                    const { bankruptcyInMonths } = customDependencies;
                    return [null, 'null'].includes(bankruptcyInMonths);
                  }}
                  onChangeV2={handleSetLoanDataValue}
                />
              </Grid>
            </Grid>

            {showSupplementalFunding && (
              <Grid>
                <DSSeparator
                  dashed
                  margin="10"
                  orientation="horizontal"
                  position="initial"
                />
                <DSHeader
                  text="Supplemental Funding"
                  fontSize={12}
                  fontWeight="bold"
                  lineHeight={1}
                  data-testid="FinanceInformationForm-all-components"
                  style={{ marginTop: '16px', marginBottom: '16px' }}
                />
                <SupplementalFunding
                  handleSetLoanDataValue={handleSetLoanDataValue}
                />
              </Grid>
            )}
          </DSAccordionItem>
        </DSAccordion>
      </Grid>
    </>
  );
};

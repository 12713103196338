import { DSAccordion, DSAccordionItem } from '@elliemae/ds-accordion';
import { DSFormLayoutBlockItem } from '@elliemae/ds-form-layout-blocks';
import { DSSingleComboBox } from '@elliemae/ds-form-single-combobox';
import { Grid } from '@elliemae/ds-grid';
import { DSHeader } from '@elliemae/ds-header';
import { DSSeparator } from '@elliemae/ds-separator';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Rights from '../../../../common/services/Rights';
import * as validations from './utils/validations';
import * as masks from '../../utils/constants';
import * as dependencies from './utils/dependencies';
import * as values from './utils/values';
import Session, {
  IS_BUYSIDE,
  IS_SELLSIDE,
  IS_VALIDATE,
} from '../../../../common/services/Session';
import { safeSum } from '../../../../common/utils/shared';
import { setLoanDataValue } from '../../../components/Form/utils/common';
import {
  useLoanDataFieldSelector,
  useUserData,
} from '../../../../data/useSelectors';
import { generateOfficeUsersComboboxOptionsWithItemLayout } from '../../../../store/lookups/utils/helper';
import { searchFormActions } from '../../../../store/searchForm';
import {
  ComboBoxForm,
  ControlledCheckboxForm,
  InputMaskForm,
} from '../../../components/Form';
import { GridForm } from '../../../components/Form/GridForm';
import {
  useLookupOptions,
  useSearchFormSelector,
} from '../../../components/Form/utils/customHooks';
import { EffectiveDate } from './components/EffectiveDate';
import {
  calculateGovtFeeAmount,
  getCheckboxesSpan,
  getFloredValueTextInput,
  getLoanAmountLabel,
  getPropertyValueLabel,
  getUpfrontFeeValue,
  getUpfrontGovtFeeLabel,
  handleTotalAmountValue,
  isFirstMortgage,
  isFirstMortgageAndHeloc,
  isGovtLoan,
  isHCLTVHidden,
  isSecondMortgage,
  isSubordinateHELOC,
} from './utils/helpers';

export const LoanInformationForm = () => {
  const dispatch = useDispatch();
  const ltvRef = useRef<any>();
  const ref = useRef(null);
  const [active, setActive] = useState<number | null>(0);
  const [validateForm, setValidateForm] = useState(false);
  const errorFields = (useSearchFormSelector('errorForm.errorFields') as any)
    .value;
  const { firstName = '', lastName = '', officeName = '' } = useUserData();
  const loanOfficerId = useLoanDataFieldSelector(
    'loanInformation.loanOfficerId',
  );
  const defaultOfficer: any[] = [
    {
      firstName: `${firstName}`,
      lastName: `${lastName}`,
      office: `${officeName}`,
      userId: parseInt(`${loanOfficerId}`, 10),
    },
  ];
  const eppsUserName = useLoanDataFieldSelector('eppsUserName');
  let officersOptions = useLookupOptions('transformedOfficeUsers') || [];
  const defaultOfficersOption =
    generateOfficeUsersComboboxOptionsWithItemLayout(
      'officeUsers',
      defaultOfficer,
    );

  if (officersOptions?.length === 0) officersOptions = defaultOfficersOption;

  const loanofficerInitialValue = loanOfficerId
    ? officersOptions.find((option) => option.value === loanOfficerId)
    : null;
  const selectedOfficerValue = eppsUserName
    ? officersOptions.find((option) => option.value === eppsUserName)
    : null;
  const [loanOfficer, setLoanOfficer] = useState(
    selectedOfficerValue ?? loanofficerInitialValue,
  );

  if (!loanOfficer) setLoanOfficer(defaultOfficersOption[1]);

  const handleSetLoanDataValue = ({ value, pathValue }) => {
    const parseValue = ['', undefined].includes(value) ? null : value;
    dispatch(setLoanDataValue(parseValue, pathValue) as any);
  };

  const handleSetLoanOfficerDataValue = useCallback(
    (newValue, value, login) => {
      const parseValue = ['', undefined].includes(value) ? null : value;
      const parseLogin = ['', undefined].includes(login) ? null : login;
      dispatch(
        setLoanDataValue(parseValue, 'loanInformation.loanOfficerId') as any,
      );
      dispatch(setLoanDataValue(parseLogin, 'eppsUserName') as any);
      setLoanOfficer(newValue);
    },
    [],
  );
  const isBuyside = !Session.get(IS_BUYSIDE) || null;
  const isSellside = !Session.get(IS_SELLSIDE) || null;
  const isValidate = !Session.get(IS_VALIDATE) || null;
  const showLoanOfficer =
    Rights.AssignLoanOfficer && (isBuyside || isSellside || isValidate);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const standardProducts = useLoanDataFieldSelector(
    'standardProducts',
  ) as unknown as any[];
  const govtUpfrontFees = useLookupOptions('fee');
  const {
    feeAmount,
    feeAmountFinanced,
    feeAmountPaidinCash,
    loanPurpose,
    vaFirstTimeUse,
    ltv,
  } = useLoanDataFieldSelector('loanInformation') as any;
  let govtFeeFactor = 0;
  if (Array.isArray(standardProducts) && standardProducts.length > 0) {
    govtFeeFactor = getUpfrontFeeValue(
      { standardProducts },
      { govtUpfrontFees, loanPurpose, vaFirstTimeUse, ltv },
    );
  }
  const clientSettings = useLookupOptions('clientSettings');
  const noClosingCost = clientSettings?.[0]?.options?.noClosingCost;
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!validateForm && errorFields && errorFields.length > 0) {
      setValidateForm(true);
      if (
        [
          'secondMortgageAmount',
          'otherPayment',
          'commitmentTypes',
          'deliveryTypes',
        ].some((field) => errorFields.includes(field))
      ) {
        setActive(0);
        dispatch(searchFormActions.setBannerIsOpen(true));
      } else {
        setActive(null);
        dispatch(searchFormActions.setBannerIsOpen(false));
      }
    }
  }, [validateForm, errorFields]);

  const [feeAmountPaidinCashChanged, setFeeAmountPaidinCashChanged] =
    useState(false);
  useEffect(() => {
    if (feeAmountPaidinCash) {
      setFeeAmountPaidinCashChanged(true);
    }
  }, []);

  return (
    <>
      <DSHeader
        text="General Loan Information"
        fontSize={16}
        fontWeight="bold"
        lineHeight={1}
        mt="xs"
        mb="xxs"
      />

      <Grid cols={10} gutter="xs" alignItems="flex-start">
        <Grid span={2}>
          <EffectiveDate handleSetLoanDataValue={handleSetLoanDataValue} />
        </Grid>
        {showLoanOfficer ? (
          <>
            {windowWidth < 1400 && <Grid span={2} />}
            <Grid span={2}>
              <DSFormLayoutBlockItem label="Loan Officer" inputID="loanOfficer">
                <DSSingleComboBox
                  placeholder="Select Loan Officer"
                  selectedValue={loanOfficer}
                  aria-required="true"
                  id="loanOfficer"
                  innerRef={ref}
                  onChange={(data) => {
                    handleSetLoanOfficerDataValue(
                      data,
                      data ? data.value : '',
                      data ? data.login : '',
                    );
                  }}
                  allOptions={officersOptions || []}
                />
              </DSFormLayoutBlockItem>
            </Grid>
            <Grid span={windowWidth < 1400 ? 4 : 6} />
          </>
        ) : (
          <Grid span={8} />
        )}
        <Grid span={2}>
          <ComboBoxForm
            required
            isNonClearable
            name="lienPos"
            label="Lien Position"
            pathValue="loanInformation.lienPosition"
            onChangeV2={handleSetLoanDataValue}
          />
        </Grid>
        <Grid span={2}>
          <ComboBoxForm
            required
            isNonClearable
            name="loanUsage"
            label="Loan Purpose"
            pathValue="loanInformation.loanPurpose"
            onChangeV2={(data) => {
              handleSetLoanDataValue(data);
              dispatch(searchFormActions.setIsRefinance(data));
            }}
          />
        </Grid>
        <Grid span={2}>
          <InputMaskForm
            required
            clearable
            maxLength={16}
            maskOpts={masks.NOT_DECIMAL_PLACES}
            placeholder="$0.00"
            name="propertyValue"
            pathValue="property.value"
            label={getPropertyValueLabel}
            onKeyUp={handleSetLoanDataValue}
            dependencies={dependencies.PROPERTY_VALUE}
            customParser={getFloredValueTextInput}
          />
        </Grid>
        <Grid span={2}>
          <InputMaskForm
            required
            clearable
            maxLength={12}
            placeholder="$0"
            maskOpts={masks.NOT_DECIMAL_PLACES}
            name="firstMortgageAmount"
            pathValue="loanInformation.firstMortgageAmount"
            label={getLoanAmountLabel}
            onKeyUp={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
            onClearField={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
            dependencies={dependencies.FIRST_MORTGAGE_AMOUNT}
            extraDependencies={dependencies.EXTRA_IS_HELOC}
          />
        </Grid>
        {/* Second mortgage fields */}
        <GridForm
          span={2}
          hidden={isFirstMortgage}
          dependencies={dependencies.LIEN_POSITION}
        >
          <InputMaskForm
            clearable
            maxLength={12}
            placeholder="$0.00"
            maskOpts={masks.DECIMAL_PLACES}
            name="totSubClosedEndMort"
            pathValue="loanInformation.totSubClosedEndMort"
            label="Other Closed End 2nd Mortgage"
            onBlur={handleSetLoanDataValue}
            onClearField={handleSetLoanDataValue}
          />
        </GridForm>
        <GridForm
          span={2}
          hidden={isFirstMortgage}
          dependencies={dependencies.LIEN_POSITION}
        >
          <InputMaskForm
            clearable
            maxLength={12}
            placeholder="$0"
            maskOpts={masks.NOT_DECIMAL_PLACES}
            name="otherPayment"
            pathValue="loanInformation.otherPayment"
            label="Monthly Housing Payment"
            onBlur={handleSetLoanDataValue}
            onClearField={handleSetLoanDataValue}
            dependencies={dependencies.OTHER_PAYMENT}
          />
        </GridForm>
        <GridForm
          span={2}
          hidden={isFirstMortgage}
          dependencies={dependencies.LIEN_POSITION}
        >
          <InputMaskForm
            clearable
            maxLength={16}
            placeholder="$0.00"
            maskOpts={masks.TWELVE_INTEGERS_NO_DECIMAL_PLACES}
            name="secondMortgageAmount"
            pathValue="loanInformation.secondMortgageAmount"
            label="Loan Amount/Initial Draw"
            onClearField={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
            onKeyUp={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
            dependencies={dependencies.LOAN_AMOUNT_INITIAL_DRAW}
          />
        </GridForm>
        {/* Second mortgage fields AND Fisrt mortgage with a subordinate HELOC */}
        <GridForm
          span={2}
          hidden={isSubordinateHELOC}
          dependencies={dependencies.LIEN_POSITION}
          extraDependencies={dependencies.EXTRA_SUBORDINATE_HELOC}
        >
          <InputMaskForm
            readOnly
            clearable
            maxLength={16}
            placeholder="$0.00"
            maskOpts={masks.DECIMAL_PLACES}
            name="otherHELOCDraws"
            pathValue="loanInformation.otherHELOCDraws"
            label="Other HELOC Draws"
            onBlur={handleSetLoanDataValue}
            onClearField={handleSetLoanDataValue}
          />
        </GridForm>
        {/* Second mortgage fields */}
        <GridForm
          span={2}
          hidden={isFirstMortgage}
          dependencies={dependencies.LIEN_POSITION}
        >
          <InputMaskForm
            required
            clearable
            maxLength={16}
            placeholder="$0.00"
            maskOpts={masks.DECIMAL_PLACES}
            name="loanAmountMaxLine"
            pathValue="loanInformation.loanAmountMaxLine"
            label="Max Loan Amount/Max Line"
            onClearField={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
            onKeyUp={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
            dependencies={dependencies.LOAN_AMOUNT_MAX_LIEN}
            extraDependencies={dependencies.EXTRA_IS_HELOC}
          />
        </GridForm>
        {/* Second mortgage fields AND Fisrt mortgage with a subordinate HELOC */}
        <GridForm
          span={2}
          hidden={isSubordinateHELOC}
          dependencies={dependencies.LIEN_POSITION}
          extraDependencies={dependencies.EXTRA_SUBORDINATE_HELOC}
        >
          <InputMaskForm
            readOnly
            clearable
            maxLength={16}
            placeholder="$0.00"
            maskOpts={masks.DECIMAL_PLACES}
            name="otherHELOCLines"
            pathValue="loanInformation.otherHELOCLines"
            label="Other HELOC Lines"
            onBlur={handleSetLoanDataValue}
            onClearField={handleSetLoanDataValue}
          />
        </GridForm>
        {/* first mortgage fields */}
        <GridForm
          span={2}
          hidden={isSecondMortgage}
          dependencies={dependencies.LIEN_POSITION}
        >
          <InputMaskForm
            maxLength={12}
            placeholder="$0"
            maskOpts={masks.NOT_NEGATIVE_VALUE_EIGHT_DIGITS}
            name="financedAmount"
            pathValue="loanInformation.financedAmount"
            label="PMI, MIP Financed"
            customParser={getFloredValueTextInput}
            onClearField={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
            onKeyUp={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
          />
        </GridForm>
        <GridForm
          span={2}
          hidden={isSecondMortgage}
          dependencies={dependencies.LIEN_POSITION}
        >
          <InputMaskForm
            readOnly
            maxLength={12}
            placeholder="$0"
            maskOpts={masks.DECIMAL_PLACES}
            pathValue="loanInformation.totalMortgageAmount"
            customValue={({ customDependencies, pathValue }) => {
              const value = handleTotalAmountValue({ customDependencies });
              handleSetLoanDataValue({ value, pathValue });
              return value;
            }}
            name="totalMortgageAmount"
            label="Total Loan Amount"
            dependencies={dependencies.TOTAL_LOAN_AMOUNT}
          />
        </GridForm>
        {/* first mortgage and isHeloc field */}
        <GridForm
          span={2}
          hidden={isFirstMortgageAndHeloc}
          dependencies={dependencies.LIEN_POSITION}
          extraDependencies={dependencies.EXTRA_IS_HELOC}
        >
          <InputMaskForm
            required
            clearable
            maxLength={12}
            placeholder="$0.00"
            maskOpts={masks.DECIMAL_PLACES}
            name="loanAmountMaxLine"
            pathValue="loanInformation.loanAmountMaxLine"
            onClearField={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
            onKeyUp={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
            label="Max Loan Amount/Max Line"
            dependencies={dependencies.LOAN_AMOUNT_MAX_LIEN}
            extraDependencies={dependencies.EXTRA_IS_HELOC}
          />
        </GridForm>
        {/* Govt fields */}
        <GridForm
          data-testid="loanInfo-govtFeeFactor"
          span={2}
          hidden={(customDependencies) => {
            return !isGovtLoan(customDependencies);
          }}
          dependencies={dependencies.UPFRONT_GOVT_FEES_FACTOR}
        >
          <InputMaskForm
            readOnly
            maxLength={12}
            useSubfix="%"
            placeholder="0%"
            maskType="PERCENT"
            maskOpts={masks.DECIMAL_PLACES_NOT_PREFIX}
            name="govtFeeFactor"
            // pathValue="loanInformation.govtFeeFactor"
            label={(customDependencies) => {
              return getUpfrontGovtFeeLabel(customDependencies, 1);
            }}
            customValue={({ customDependencies }) => {
              let value = 0;
              if (isGovtLoan({ customDependencies })) {
                if (govtFeeFactor) {
                  value = govtFeeFactor;
                }
              }
              return value;
            }}
            dependencies={dependencies.UPFRONT_GOVT_FEES_FACTOR}
          />
        </GridForm>
        <GridForm
          data-testid="loanInfo-feeAmount"
          span={2}
          hidden={(customDependencies) => {
            return !isGovtLoan(customDependencies);
          }}
          dependencies={dependencies.UPFRONT_GOVT_FEES_AMOUNT}
        >
          <InputMaskForm
            readOnly
            maxLength={12}
            placeholder="$0"
            maskOpts={masks.NOT_NEGATIVE_DECIMAL_PLACES}
            name="feeAmount"
            pathValue="loanInformation.feeAmount"
            customValue={({ customDependencies, pathValue }) => {
              if (!feeAmountPaidinCashChanged && feeAmountPaidinCash)
                return feeAmount;
              let value = 0;
              let feeAmountFinancedValue = 0;
              if (isGovtLoan({ customDependencies })) {
                const { firstMortgageAmount, secondMortgageAmount } =
                  customDependencies;
                const baseLoanAmount = isFirstMortgage({ customDependencies })
                  ? firstMortgageAmount
                  : secondMortgageAmount;
                const calculatedGovtFeeAmount = calculateGovtFeeAmount(
                  baseLoanAmount,
                  govtFeeFactor,
                );
                value = calculatedGovtFeeAmount;

                const sumOfFinancedNdPaidInCash = safeSum(
                  feeAmountFinanced,
                  feeAmountPaidinCash,
                );
                if (sumOfFinancedNdPaidInCash !== calculatedGovtFeeAmount) {
                  feeAmountFinancedValue = calculatedGovtFeeAmount;
                  handleSetLoanDataValue({
                    value: 0,
                    pathValue: 'loanInformation.feeAmountPaidinCash',
                  });
                } else {
                  feeAmountFinancedValue = feeAmountFinanced;
                }
              }
              handleSetLoanDataValue({ value, pathValue });
              handleSetLoanDataValue({
                value: feeAmountFinancedValue,
                pathValue: 'loanInformation.feeAmountFinanced',
              });

              return value;
            }}
            label={(customDependencies) => {
              return getUpfrontGovtFeeLabel(customDependencies, 2);
            }}
            dependencies={dependencies.UPFRONT_GOVT_FEES_AMOUNT}
            // extraDependencies={govtFeeFactor}
          />
        </GridForm>
        <GridForm
          span={2}
          hidden={(customDependencies) => {
            return !isGovtLoan(customDependencies);
          }}
          dependencies={dependencies.GOVT_FEE_FINANCED_AMOUNT}
        >
          <InputMaskForm
            maxLength={12}
            placeholder="$0"
            maskOpts={masks.NOT_NEGATIVE_DECIMAL_PLACES}
            name="feeAmountFinanced"
            pathValue="loanInformation.feeAmountFinanced"
            label={(customDependencies) => {
              return getUpfrontGovtFeeLabel(customDependencies, 3);
            }}
            onKeyUp={(data) => {
              handleSetLoanDataValue(data);
            }}
            onClearField={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
            dependencies={dependencies.GOVT_FEE_FINANCED_AMOUNT}
          />
        </GridForm>
        <GridForm
          span={2}
          hidden={(customDependencies) => {
            return !isGovtLoan(customDependencies);
          }}
          dependencies={dependencies.UPFRONT_GOVT_FEES_PAID_IN_CASH}
        >
          <InputMaskForm
            clearable
            maxLength={12}
            placeholder="$0"
            maskOpts={masks.NOT_NEGATIVE_DECIMAL_PLACES}
            name="feeAmountPaidinCash"
            pathValue="loanInformation.feeAmountPaidinCash"
            label={(customDependencies) => {
              return getUpfrontGovtFeeLabel(customDependencies, 4);
            }}
            onKeyUp={(data) => {
              handleSetLoanDataValue(data);
              setFeeAmountPaidinCashChanged(true);
            }}
            dependencies={dependencies.UPFRONT_GOVT_FEES_PAID_IN_CASH}
          />
        </GridForm>
        <Grid span={10}>
          <DSSeparator
            dashed
            margin="none"
            orientation="horizontal"
            position="initial"
          />
        </Grid>
        <Grid span={10}>
          <DSAccordion activeValue={active} onChange={setActive as any}>
            <DSAccordionItem
              title={
                active === 0 ? 'Hide Detailed Entry' : 'Open Detailed Entry'
              }
              key={0}
              value={0}
            >
              <Grid cols={['repeat(8, 0.5fr)']} gutter="xs">
                <GridForm
                  span={2}
                  hidden={isSecondMortgage}
                  dependencies={dependencies.LIEN_POSITION}
                >
                  <InputMaskForm
                    clearable
                    maxLength={16}
                    placeholder="$0.00"
                    secondMortgageAmountField
                    name="secondMortgageAmount"
                    label="2nd Mortgage Balance"
                    maskOpts={masks.DECIMAL_PLACES}
                    onKeyUp={handleSetLoanDataValue}
                    onClearField={handleSetLoanDataValue}
                    readOnly={validations.handleIsSecondMortgageAmount}
                    pathValue="loanInformation.secondMortgageAmount"
                    dependencies={dependencies.SECOND_MORTGAGE_BALANCE}
                    extraDependencies={
                      dependencies.EXTRA_SECOND_MORTGAGE_AMOUNT
                    }
                  />
                </GridForm>

                <GridForm
                  span={2}
                  hidden={isSecondMortgage}
                  dependencies={dependencies.LIEN_POSITION}
                >
                  <InputMaskForm
                    clearable
                    maxLength={12}
                    placeholder="$0"
                    maskOpts={masks.NOT_DECIMAL_PLACES}
                    name="otherPayment"
                    label="2nd Mortgage Payment"
                    onKeyUp={handleSetLoanDataValue}
                    onClearField={handleSetLoanDataValue}
                    pathValue="loanInformation.otherPayment"
                    dependencies={dependencies.SECOND_MORTGAGE_AMOUNT}
                  />
                </GridForm>

                <GridForm
                  span={2}
                  hidden={validations.isHideCommitmentAndDeliveryTpe}
                  originalLoanInfoDependencies={dependencies.CHANNEL_INFO}
                >
                  <ComboBoxForm
                    required
                    isNonClearable
                    name="commitmentTypes"
                    label="Commitment Type"
                    pathValue="loanInformation.commitmentType"
                    placeholder="Select Commitment Type"
                    onChangeV2={handleSetLoanDataValue}
                    dependencies="loanInformation.loanChannel"
                  />
                </GridForm>
                <GridForm
                  span={2}
                  hidden={validations.isHideCommitmentAndDeliveryTpe}
                  originalLoanInfoDependencies={dependencies.CHANNEL_INFO}
                >
                  <ComboBoxForm
                    required
                    isNonClearable
                    name="deliveryTypes"
                    label="Delivery Type"
                    pathValue="deliveryType"
                    placeholder="Select Delivery Type"
                    customValue={({ value, customDependencies }) => {
                      const { commitmentType } = customDependencies;
                      let initValue = value;
                      if (commitmentType === 1 || commitmentType === 2) {
                        initValue = commitmentType;
                        handleSetLoanDataValue({
                          value: initValue,
                          pathValue: 'deliveryType',
                        });
                      }
                      return initValue;
                    }}
                    customDisabled
                    initialValue={0}
                    onChangeV2={handleSetLoanDataValue}
                    dependencies={dependencies.DELIVERY_TYPE}
                  />
                </GridForm>
              </Grid>
            </DSAccordionItem>
          </DSAccordion>
        </Grid>
        <Grid span={1}>
          <InputMaskForm
            name="LTV"
            label="LTV"
            useSubfix="%"
            maxLength={12}
            placeholder="0%"
            maskType="PERCENT"
            readOnly={isSecondMortgage}
            customValue={(data) => {
              let value: string | number = 0;

              if (!ltvRef.current) value = values.ltvValue(data);
              else value = data.value;
              handleSetLoanDataValue({ value, pathValue: data.pathValue });
              return value;
            }}
            onBlur={(data) => {
              const { customDependencies } = data;
              const { isHELOC } = customDependencies;
              const getLoanValue = values.loanAmountValue(data);
              const getLoanMaxValue = values.calculateLoanAmountMaxLine(data);
              handleSetLoanDataValue(data);
              ltvRef.current = true;
              if (isHELOC) {
                handleSetLoanDataValue({
                  value: getLoanMaxValue,
                  pathValue: 'loanInformation.loanAmountMaxLine',
                });
              } else {
                handleSetLoanDataValue({
                  value: getLoanValue,
                  pathValue: 'loanInformation.firstMortgageAmount',
                });
              }
            }}
            pathValue="loanInformation.ltv"
            maskOpts={masks.LTV_PERCENT}
            dependencies={dependencies.LTV}
            extraDependencies={dependencies.EXTRA_IS_HELOC}
          />
        </Grid>
        <Grid span={1}>
          <InputMaskForm
            readOnly
            name="CLTV"
            label="CLTV"
            useSubfix="%"
            maxLength={12}
            maskType="PERCENT"
            placeholder="0.000%"
            maskOpts={masks.PERCENT_VALUES}
            customValue={values.cltvValue}
            dependencies={dependencies.CLTV}
            extraDependencies={dependencies.EXTRA_CLTV}
          />
        </Grid>
        <GridForm
          span={1}
          hidden={isHCLTVHidden}
          dependencies={dependencies.LIEN_POSITION}
          extraDependencies={dependencies.EXTRA_IS_HELOC}
        >
          <InputMaskForm
            readOnly
            clearable
            name="HCLTV"
            useSubfix="%"
            maxLength={12}
            placeholder="0%"
            maskType="PERCENT"
            maskOpts={masks.PERCENT_VALUES}
            customValue={values.hcltvValue}
            dependencies={dependencies.HCLTV}
            extraDependencies={dependencies.EXTRA_IS_HELOC}
            label="HCLTV"
          />
        </GridForm>
        <GridForm
          span={1}
          hidden={validations.isCashOutRefinance}
          dependencies={dependencies.LOAN_PURPOSE}
          extraDependencies={dependencies.EXTRA_IS_HELOC}
        >
          <InputMaskForm
            required
            name="cashOut"
            maxLength={12}
            placeholder="$0"
            maskOpts={masks.NOT_DECIMAL_PLACES}
            pathValue="loanInformation.cashOut"
            onBlur={handleSetLoanDataValue}
            label="Cash Out"
          />
        </GridForm>
        <GridForm
          mt="2px"
          gutter="xs"
          height="100%"
          wrap="nowrap"
          alignItems="center"
          justifyContent="start"
          span={getCheckboxesSpan}
          cols={['auto', 'auto', 'auto', 'auto']}
          dependencies={[
            ...dependencies.LIEN_POSITION,
            ...dependencies.LOAN_PURPOSE,
          ]}
          extraDependencies={dependencies.EXTRA_IS_HELOC}
        >
          <ControlledCheckboxForm
            label="Community/Affordable Second"
            name="communityAffordableSecond"
            pathValue="loanInformation.communityAffordableSecond"
            onChange={handleSetLoanDataValue}
          />
          {noClosingCost && (
            <ControlledCheckboxForm
              label="No Closing Costs"
              name="noClosingCost"
              pathValue="loanInformation.noClosingCost"
              onChange={handleSetLoanDataValue}
            />
          )}
          <ControlledCheckboxForm
            label="Lender Fee Waiver"
            name="lenderFeeWaiver"
            pathValue="loanInformation.lenderFeeWaiver"
            onChange={handleSetLoanDataValue}
          />
          <GridForm
            hidden={validations.isRefinanceType}
            extraDependencies={dependencies.EXTRA_REFINANCE_TYPE}
          >
            <ControlledCheckboxForm
              label="Closing Costs are included in the Loan Amount"
              name="includeClosingCost"
              pathValue="loanInformation.includeClosingCost"
              onChange={handleSetLoanDataValue}
            />
          </GridForm>
        </GridForm>
        <Grid span={10}>
          <DSSeparator
            type="non-form"
            margin="none"
            orientation="horizontal"
            position="initial"
          />
        </Grid>
      </Grid>
    </>
  );
};

/* eslint-disable @typescript-eslint/await-thenable */
import { WarningTriangle } from '@elliemae/ds-icons';
import { TooltipTextProvider } from '@elliemae/ds-truncated-tooltip-text';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { NSSampleSchema, SchemaAndRulesDto } from '@frontend/pricing-search';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  dispatchOriginData,
  dispatchUserData,
  getCustomSearchSchemaData,
  getInitData,
  IS_INVALID_CREDENTIAL,
  IS_INVALID_TOKEN,
  IS_RECONCILE,
  setMockInitData,
} from '../common/app-helpers';
import Common from '../common/services/Common';
import Session, {
  ALL_SUMMARY_PARAMS,
  IS_BUYSIDE,
  IS_JWT_TOKEN,
  IS_LO,
  IS_LOCK_EXTENSION,
  IS_LOCK_REQUEST_FORM_LOCK_EXTENSION,
  IS_SECONDARY_LOCK,
  IS_SELLSIDE,
  IS_STANDALONE,
} from '../common/services/Session';
import { buysideRequested } from '../common/utils/constants/ErrorCodes';
import { startLog } from '../common/utils/logger';
import {
  openGlobalErrorModalAction,
  openNoLoanAmountErrorModal,
} from '../data/globalErrorModal/actionCreators';
import { lookupsActions } from '../store/lookups/index';
import { LockConfirmWrapper } from '../view/LockConfirm/LockConfirmWrapper';
import { LockExtension } from '../view/LockExtension/LockExtension';
import { LockSummary } from '../view/LockSummary/LockSummary';
import { Login } from '../view/Login';
import { SearchForm } from '../view/SearchForm/SearchForm';
import { SearchResult } from '../view/SearchResult';
import { Header } from '../view/components/Header/index';
import {
  StyledEppsContainerBody,
  StyledEppsContainerHeader,
  StyledEppsParentContainer,
} from '../view/global/styles/styled';
import {
  PATH_LOCK_EXTENSION,
  PATH_LOCK_SUMMARY,
  PATH_LOGIN,
  PATH_SEARCH_FOR_PRODUCTS,
  PATH_SEARCH_RESULTS,
} from './constants';
import { ScreenLoader } from '../view/components/ScreenLoader';
import { GlobalErrorModal } from '../view/components/GlobalErrorModal';
import { useLoanInformationSelector } from '../data/useSelectors';
import { useLookupOptions } from '../view/components/Form/utils/customHooks';

const App: React.FC = () => {
  const [moveNext, setMoveNext] = useState(false);
  const [isError, setIsError] = useState(false);
  const [initData, setInitData] = useState(undefined);
  const [roleName, setRoleName] = useState('');
  const [showCustomSearch, setShowCustomSearch] = useState(false);
  const [customSearchSchema, setCustomSearchSchema] = useState<
    typeof SchemaAndRulesDto | undefined
  >(undefined);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const navigate = useNavigate();
  const isBuyside = Session.get(IS_BUYSIDE);
  const isSellside = Session.get(IS_SELLSIDE);
  const isJWTToken = Session.get(IS_JWT_TOKEN);

  const isLO = Session.get(IS_LO);
  const dispatch = useDispatch();
  const govtUpfrontFees = useLookupOptions('fee') || [];

  const fetchData = async (isRefresh = false) => {
    const data = await getInitData(dispatch, isRefresh ?? false, setIsError);
    const search = await getCustomSearchSchemaData();
    if (search === undefined) {
      setShowCustomSearch(false);
      // TODO: Replace with api response when api is complete and get channel from origin data
      setCustomSearchSchema(NSSampleSchema as any);
    }
    if (data.code === undefined) {
      dispatchUserData(dispatch);
      await setUserClientRoles();
      dispatch(lookupsActions.getLookupsDataAction({ dispatch } as any));
    }
    setInitData(data);
  };

  useEffect(() => {
    startLog(buysideRequested);
    Session.set(ALL_SUMMARY_PARAMS, {});

    if (!Session.get(IS_STANDALONE)) {
      fetchData();
    } else {
      // TODO(ngen): Might need to do more work to support this mock data flow
      setMockInitData(dispatch);
      setMoveNext(true);
    }
  }, []);

  useEffect(() => {
    if (isError) {
      dispatch(
        openGlobalErrorModalAction({
          onCloseCallback: () => Common.closeApplication(),
        }) as any,
      );
    }
  }, [isError]);

  const setUserClientRoles = async () => {
    const [, rightsResponse] = await Promise.all([
      Common.setUserRoles(),
      Common.setClientRoles(),
    ]);
    setRoleName(rightsResponse?.roleName);
    if (!rightsResponse?.rights) {
      dispatch(
        openGlobalErrorModalAction({
          title: 'You have not been assigned a role!',
          errorMessage:
            'You will have limited access to any features until you have been assigned a role. Please contact your administrator.',
          icon: <WarningTriangle size="xxl" color={['warning', '600']} />,
        }) as any,
      );
    }
  };

  useEffect(() => {
    if (initData) {
      const {
        notTokenCredResource,
        errorLoanAmountBlank,
        hasLoanAmount,
        loanAmountBlank,
        lienPosition,
        originData,
        code,
        isRedirectToError,
      } = initData;
      if (!notTokenCredResource) {
        if (errorLoanAmountBlank || !hasLoanAmount) {
          dispatch(openNoLoanAmountErrorModal() as any);
        }
        if (
          !loanAmountBlank &&
          lienPosition &&
          hasLoanAmount &&
          govtUpfrontFees.length > 0
        ) {
          dispatchOriginData(dispatch, originData, govtUpfrontFees);
          setMoveNext(true);
        }
      }
      if (initData[IS_RECONCILE] === IS_RECONCILE) {
        fetchData(true);
      }
      if (isRedirectToError) {
        setMoveNext(true);
        setIsError(true);
      }
      if (code && code !== IS_INVALID_TOKEN && code !== IS_INVALID_CREDENTIAL) {
        throw new Error();
      }
    }
  }, [initData, govtUpfrontFees]);

  useEffect(() => {
    if (moveNext) {
      if (!isLO && !isJWTToken) {
        navigate(PATH_LOGIN);
      } else if (Session.get(IS_LOCK_EXTENSION)) {
        navigate(PATH_LOCK_EXTENSION);
      } else if (Session.get(IS_BUYSIDE) || Session.get(IS_SELLSIDE)) {
        navigate(PATH_LOCK_SUMMARY);
      } else {
        navigate(PATH_SEARCH_RESULTS);
      }
    }
  }, [moveNext]);

  // Callback function to handle successful login
  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    navigate('/search-results');
  };

  const loanInfo = useLoanInformationSelector() || {};
  const schemaForm = useForm<any>({
    mode: 'onChange',
    // TODO: forumalate resolver to qualification request schema
    // resolver: yupResolver(SchemaAndRulesDto),
    defaultValues: {},
    resetOptions: { keepValues: false },
  });

  useEffect(() => {
    if (loanInfo && roleName && Object.keys(loanInfo).length > 0) {
      schemaForm.reset(loanInfo);
      schemaForm.setValue('nonLoanValues.role', roleName ?? '');
    }
  }, [loanInfo, schemaForm, roleName]);

  const renderContent = useMemo(() => {
    return (
      <TooltipTextProvider>
        <StyledEppsContainerBody>
          {moveNext && (
            <Routes>
              <Route
                path={PATH_SEARCH_FOR_PRODUCTS}
                element={
                  <SearchForm
                    customSchema={customSearchSchema}
                    schemaForm={schemaForm}
                    showCustomSearch={showCustomSearch}
                  />
                }
              />
              <Route
                path={PATH_LOCK_SUMMARY}
                element={
                  isBuyside || isSellside ? (
                    <LockConfirmWrapper />
                  ) : (
                    <LockSummary />
                  )
                }
              />
              <Route path={PATH_LOCK_EXTENSION} element={<LockExtension />} />

              {/* Login Route */}
              <Route
                path="/login"
                element={<Login onLoginSuccess={handleLoginSuccess} />} // Pass the callback
              />

              {/* Search Results Route */}
              <Route path="/search-results" element={<SearchResult />} />
            </Routes>
          )}
        </StyledEppsContainerBody>
      </TooltipTextProvider>
    );
  }, [isLoggedIn, moveNext]);

  const Landing = (Content: JSX.Element) => {
    return (
      <>
        <StyledEppsParentContainer>
          <StyledEppsContainerHeader>
            <Header
              schemaForm={schemaForm}
              showCustomSearch={showCustomSearch}
            />
          </StyledEppsContainerHeader>
          {Content}
        </StyledEppsParentContainer>
        <ScreenLoader />
        <GlobalErrorModal />
      </>
    );
  };

  return Landing(renderContent);
};

export default App;

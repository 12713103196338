import { combineReducers } from 'redux';
import screenLoader from '../../data/screenLoader/reducer';

import globalErrorModal from '../../data/globalErrorModal/reducer';
import landing from '../../data/landing/reducer';
import lockConfirm from '../../data/lockConfirm/reducer';
import lockDeskStatus from '../../data/lockDeskStatus/reducer';
import lockSummaryOld from '../../data/lockSummary/reducer';
import origin from '../../data/origin/reducer';
import personaAccess from '../../data/personaAccess/reducer';
import searchFormReducer from '../../data/searchForm/reducer';
import EPPS from './AppReducer';
// New reducers flow -> in progress
import lookups from '../../store/lookups';
import searchForm from '../../store/searchForm';

export const rootReducer = combineReducers({
  globalErrorModal,
  screenLoader,
  landing,
  EPPS,
  epps: combineReducers({
    origin,
    lockSummary: lockSummaryOld,
    lockConfirm,
    searchForm: searchFormReducer,
  }),
  personaAccess,
  lockDeskStatus,
  eppsData: combineReducers({
    searchForm,
    lookups,
  }),
});

import { Grid } from '@elliemae/ds-grid';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  ComboBoxForm,
  GridForm,
  InputMaskForm,
} from '../../../../../components/Form';
import { setLoanDataValue } from '../../../../../components/Form/utils/common';
import { useLoanDataFieldSelector } from '../../../../../components/Form/utils/customHooks';
import * as masks from '../../../../utils/constants';
import { useDocumentationTypes } from './utils/customHooks';
import * as dependencies from './utils/dependencies';

export const DocumentationTypes = () => {
  const dispatch = useDispatch();
  // TODO: We should look for a better way to do this documentation type flow
  const { asset, income, employment, documentationTypes } =
    useDocumentationTypes();

  const loanDataIncome = useLoanDataFieldSelector('income') as any;
  const finalIncome = loanDataIncome.value || income;

  const handleSetLoanDataValue = ({ value, pathValue }) => {
    const parseValue = ['', undefined].includes(value) ? null : value;
    dispatch(setLoanDataValue(parseValue, pathValue) as any);
  };
  useEffect(() => {
    if (typeof income === 'number' && loanDataIncome.value === null) {
      handleSetLoanDataValue({
        value: income,
        pathValue: 'income',
      });
    }
  }, [asset, income, employment]);

  const handleOnChangeDocumentationType = (type, value) => {
    const setValue = parseInt(value, 10);
    const employmentValue = type === 'employment' ? setValue : employment;
    const incomeValue = (type === 'income' ? setValue : income) as number;
    const assetValue = (type === 'assets' ? setValue : asset) as number;
    const reverseEmploymentValue =
      employmentValue === 1 || employmentValue === 2 ? 100 : 0;
    if (!isEmpty(documentationTypes)) {
      const documentationType = documentationTypes.find(
        (x) =>
          x.employment <= reverseEmploymentValue &&
          x.income <= incomeValue &&
          x.assets <= assetValue,
      );
      handleSetLoanDataValue({
        value: documentationType.documentationId,
        pathValue: 'documentationLevel',
      });

      if (type === 'employment') {
        handleSetLoanDataValue({
          value: !!(employmentValue === 2),
          pathValue: 'loanInformation.selfEmployedBorrower',
        });
        handleSetLoanDataValue({
          value: !!(employmentValue === 2),
          pathValue: 'loanInformation.selfEmployedCoBorrower',
        });
      }
    }
  };

  return (
    <>
      <ComboBoxForm
        required
        name="employment"
        label="Employment"
        pathValue="employment"
        isNonClearable
        initialValue={employment}
        onChangeV2={({ value, pathValue }) => {
          handleOnChangeDocumentationType(pathValue, value);
          handleSetLoanDataValue({ value, pathValue });
        }}
      />

      <ComboBoxForm
        required
        name="assets"
        label="Assets"
        pathValue="asset"
        isNonClearable
        initialValue={asset}
        dependencies={dependencies.ASSET}
        onChangeV2={({ value, pathValue }) => {
          handleOnChangeDocumentationType(pathValue, value);
          handleSetLoanDataValue({ value, pathValue });
        }}
      />

      <ComboBoxForm
        required
        name="income"
        label="Income"
        pathValue="income"
        isNonClearable
        initialValue={income}
        dependencies={dependencies.INCOME}
        onChangeV2={({ value, pathValue }) => {
          handleOnChangeDocumentationType(pathValue, value);
          handleSetLoanDataValue({ value, pathValue });
          handleSetLoanDataValue({
            value: value === 70 ? 3 : 1,
            pathValue: 'borrowerFinancialHistory.nonQMDocLevel',
          });
        }}
      />

      <GridForm
        span={1}
        dependencies={dependencies.EXTRA_NONQM_DOCLEVEL}
        hidden={({ customDependencies }) => {
          const { standardProducts, income: incomeValue } = customDependencies;
          const getIncome = incomeValue ?? income;
          return !(
            standardProducts?.includes(6) && [70, 100]?.includes(getIncome)
          );
        }}
      >
        <Grid cols={['100%']}>
          <ComboBoxForm
            required
            isNonClearable
            name="nonQMDocLevel"
            label="Non-QM Doc Level"
            initialValue={finalIncome === 70 ? 3 : 1}
            placeholder="Select Non-QM Doc Level"
            pathValue="borrowerFinancialHistory.nonQMDocLevel"
            onChangeV2={handleSetLoanDataValue}
            optionsFilter={(options) =>
              options?.filter(
                (opt) =>
                  (finalIncome === 100 && opt.value <= 2) ||
                  (finalIncome === 70 && opt.value > 2),
              )
            }
          />
        </Grid>
      </GridForm>

      <GridForm
        span={1}
        dependencies={dependencies.EXTRA_NONQM_DOCLEVEL}
        hidden={({ customDependencies }) => {
          const { standardProducts, income: incomeValue } = customDependencies;
          const getIncome = incomeValue ?? income;
          return !(standardProducts?.includes(6) && [0]?.includes(getIncome));
        }}
      >
        <Grid cols={['100%']}>
          <InputMaskForm
            maxLength={4}
            placeHolder="0"
            maskOpts={masks.INTEGER_LIMIT_ONE}
            name="dscr"
            pathValue="borrowerFinancialHistory.dscr"
            label="DSCR"
            onBlur={handleSetLoanDataValue}
            onClearField={handleSetLoanDataValue}
          />
        </Grid>
      </GridForm>
    </>
  );
};

import moment from 'moment';
import { safeSum } from '../../shared';
import {
  minmaxValidation,
  minmaxValidation1,
  numberFieldSanitizer,
} from './helpers';

const loanInformationValidators = {
  lienPos: {
    fieldName: 'lienPos',
    required: true,
    message: 'Required Field',
  },
  loanUsage: {
    fieldName: 'loanUsage',
    allowedFalseyValues: [0],
    required: true,
    message: 'Required Field',
    equal: { value: 99, message: 'Loan Purpose is invalid.' },
  },
  commitmentTypes: {
    fieldName: 'commitmentTypes',
    required: ({ dependencies, required }) => {
      if (dependencies.value === 4) return true;
      return required;
    },
    message: 'Required Field',
  },
  deliveryTypes: {
    fieldName: 'deliveryTypes',
    required: ({ dependencies, required }) => {
      if (dependencies.value === 4) return true;
      return required;
    },
    message: 'Required Field',
  },
  propertyValue: {
    fieldName: 'propertyValue',
    required: ({ required }) => required,
    sanitizer: numberFieldSanitizer,
    message: 'Required Field',
    minmax: minmaxValidation,
  },
  firstMortgageAmount: {
    fieldName: 'firstMortgageAmount',
    required: ({ required }) => required,
    sanitizer: numberFieldSanitizer,
    message: 'Required Field',
    minmax: minmaxValidation1,
  },
  financedAmount: {
    fieldName: 'financedAmount',
    allowedFalseyValues: [0],
    required: ({ required }) => required,
    sanitizer: numberFieldSanitizer,
    message: 'Required Field',
    minmax: minmaxValidation1,
  },
  secondMortgageAmount: {
    fieldName: 'secondMortgageAmount',
    required: ({ dependencies, required }) => {
      const { lienPosition } = dependencies;
      if (lienPosition === 2) return true;
      return required;
    },
    sanitizer: numberFieldSanitizer,
    message: 'Required Field',
    minmax: minmaxValidation,
    customValidator: (data, { dependencies }) => {
      const { secondMortgageAmount } = data;
      const { otherPayment, lienPosition } = dependencies;
      const result: {
        hasError: boolean;
        message: string | null;
      } = { hasError: false, message: null };

      if (
        ['', null].includes(secondMortgageAmount) &&
        (otherPayment || lienPosition)
      ) {
        if (otherPayment && lienPosition === 1) {
          result.hasError = true;
          result.message = 'Required Field';
          return result;
        }
        if (lienPosition === 2) {
          result.hasError = true;
          result.message = 'Required Field';
          return result;
        }
      }
      if (secondMortgageAmount <= 0 && lienPosition === 2) {
        result.hasError = true;
        result.message = 'Value must be greater than 0';
      } else if (lienPosition === 1) {
        if (
          !['', null].includes(secondMortgageAmount) &&
          secondMortgageAmount <= 0
        ) {
          result.hasError = true;
          result.message = 'Value must be greater than 0';
        }
      }
      return result;
    },
  },
  otherPayment: {
    fieldName: 'otherPayment',
    required: ({ dependencies, required }) => {
      const { lienPosition } = dependencies;
      if (lienPosition === 2) return true;
      return required;
    },
    sanitizer: numberFieldSanitizer,
    message: 'Required Field',
    customValidator: (data, { dependencies }) => {
      const { otherPayment } = data;
      const { secondMortgageAmount, lienPosition } = dependencies;
      const result: {
        hasError: boolean;
        message: string | null;
      } = { hasError: false, message: null };
      if (
        ['', null].includes(otherPayment) &&
        (secondMortgageAmount || lienPosition)
      ) {
        if (secondMortgageAmount && lienPosition === 1) {
          result.hasError = true;
          result.message = 'Required Field';
          return result;
        }
        if (lienPosition === 2) {
          result.hasError = true;
          result.message = 'Required Field';
          return result;
        }
      }
      if (otherPayment <= 0 && lienPosition === 2) {
        result.hasError = true;
        result.message = 'Value must be greater than 0';
      } else if (lienPosition === 1) {
        if (!['', null].includes(otherPayment) && otherPayment <= 0) {
          result.hasError = true;
          result.message = 'Value must be greater than 0';
        }
      }
      return result;
    },
  },
  totSubClosedEndMort: {
    fieldName: 'totSubClosedEndMort',
    allowedFalseyValues: [0],
    sanitizer: numberFieldSanitizer,
    message: 'Required Field',
    minmax: minmaxValidation,
  },
  loanAmountMaxLine: {
    fieldName: 'loanAmountMaxLine',
    required: ({ required, dependencies }) => {
      const { lienPosition, isHELOC } = dependencies;
      if (lienPosition === 2 && !isHELOC) return !required;
      return required;
    },
    sanitizer: numberFieldSanitizer,
    message: 'Required Field',
    customValidator: (data, { dependencies }) => {
      const { loanAmountMaxLine } = data;
      const { isHELOC, lienPosition } = dependencies;
      const result: {
        hasError: boolean;
        message: string | null;
      } = { hasError: false, message: null };
      if (!isHELOC && lienPosition === 2 && loanAmountMaxLine < 0) {
        result.hasError = true;
        result.message = 'Value must be greater than 0';
      } else if (!(!isHELOC && lienPosition === 2) && loanAmountMaxLine <= 0) {
        if (['', null].includes(loanAmountMaxLine)) {
          result.hasError = true;
          result.message = 'Required Field';
          return result;
        }
        if (loanAmountMaxLine <= 0) {
          result.hasError = true;
          result.message = 'Value must be greater than 0';
        }
      }
      if (loanAmountMaxLine && loanAmountMaxLine > 999999999) {
        result.hasError = true;
        result.message = 'Must be lower than 999,999,999';
      }
      return result;
    },
  },
  cashOut: {
    allowedFalseyValues: [0],
    fieldName: 'cashOut',
    required: true,
    message: 'Required Field',
  },
  effectiveDate: {
    fieldName: 'effectiveDate',
    required: ({ required }) => required,
    customValidator: (data, dependencies) => {
      const { effectiveDate } = data;
      const dataRetentionDays = dependencies.dependencies as number;
      const parsedEffectiveDate = moment(effectiveDate);
      const xDaysBeforeToday = moment().subtract(dataRetentionDays, 'days');
      const xDaysBeforeTodayPlusOne = moment().subtract(
        dataRetentionDays + 1,
        'days',
      );

      const result: { hasError: boolean; message: string | null } = {
        hasError: false,
        message: null,
      };
      if (parsedEffectiveDate.isBefore(xDaysBeforeTodayPlusOne)) {
        result.hasError = true;
        result.message = `Effective Date must not be prior to ${xDaysBeforeTodayPlusOne.format(
          'MM/DD/YYYY',
        )}.`;
      }
      return result;
    },
  },
  // TODO: Uncomment after fields are populated with values from API
  feeAmountFinanced: {
    fieldName: 'feeAmountFinanced',
    customValidator: (data, { dependencies }) => {
      const result: { hasError: boolean; message: string | null } = {
        hasError: false,
        message: null,
      };
      const financedAmount = Object.values(data)[0];
      const { feeAmount, feeAmountPaidinCash } = dependencies;
      const sumOfAmountAndPaidInCash = safeSum(
        financedAmount,
        feeAmountPaidinCash,
      );

      if (sumOfAmountAndPaidInCash !== feeAmount) {
        result.hasError = true;
        result.message =
          'The sum of Financed and Paid in Cash must equal the total upfront fee.';
      }
      return result;
    },
  },
  feeAmountPaidinCash: {
    fieldName: 'feeAmountPaidinCash',
    customValidator: (data, { dependencies }) => {
      const result: { hasError: boolean; message: string | null } = {
        hasError: false,
        message: null,
      };
      const feeAmountPaidinCash = Object.values(data)[0];
      const { feeAmount, feeAmountFinanced } = dependencies;
      const sumOfAmountAndPaidInCash = safeSum(
        feeAmountFinanced,
        feeAmountPaidinCash,
      );

      if (sumOfAmountAndPaidInCash !== feeAmount) {
        result.hasError = true;
        result.message =
          'The sum of Financed and Paid in Cash must equal the total upfront fee.';
      }
      return result;
    },
  },
};

export default loanInformationValidators;

import { DSModal, MODAL_SUB_TYPE_V2, MODAL_TYPE_V2 } from '@elliemae/ds-modal';
import { getAppConfigValue } from '@elliemae/pui-app-sdk';
import { useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Common from '../../../../common/services/Common';
import Session, {
  ALL_SUMMARY_PARAMS,
  IS_BUYSIDE,
  IS_VALIDATE,
  ORIGIN_LOCKID,
  ORIGIN_TRANSACTION_TYPE,
} from '../../../../common/services/Session';
import { isEmpty } from '../../../../common/utils/shared';
import {
  cancelLockAction,
  denyActiveLockAction,
  denyLockRequestAction,
  updateAndConfirmAction,
} from '../../../../data/lockConfirm/actionCreators';
import {
  useCompareDataSelector,
  useLoanDataFieldSelector,
  useOriginalLoanInformationSelector,
} from '../../../../data/useSelectors';
import { PATH_LOCK_SUMMARY } from '../../../../route/constants';
import { FooterButtons } from '../../../components/FooterButtons';
import { CommentModal } from '../CommentModal';
import { getFooterButtons } from './helpers';

export const FooterActionButtons = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [denyLockModal, setDenyLockModal] = useState(false);
  const [cancelLockModal, setCancelLockModal] = useState(false);
  const [denyActiveLockModal, setDenyActiveLockModal] = useState(false);
  const [openLockAndConfirmModal, setOpenLockAndConfirmModal] = useState(false);
  const lockRequests = useOriginalLoanInformationSelector(
    'lockRequests',
    [],
  ) as any[];
  const { changeCounter: pricingChangesCount = 0 } =
    useCompareDataSelector() as any;
  const loanFieldChanges = useLoanDataFieldSelector(
    'additionalLockInfo.lockCompareResults',
    {},
  ) as any;
  const loanFieldChangesCount = useMemo(
    () => Object.values(loanFieldChanges).flat().length,
    [loanFieldChanges],
  );

  const isValidateSubmitButtonEnabled = useSelector(
    ({
      epps: { lockConfirm: { validateSubmitButtonEnabled = false } = {} } = {},
    }) => validateSubmitButtonEnabled,
    shallowEqual,
  );

  const isUpdateLockButtonDisabled = useMemo(() => {
    const notNewProduct = isEmpty(Session.get(ALL_SUMMARY_PARAMS));
    return (
      Session.get(IS_VALIDATE) &&
      pricingChangesCount === 0 &&
      loanFieldChangesCount === 0 &&
      !isValidateSubmitButtonEnabled &&
      notNewProduct
    );
  }, [
    pricingChangesCount,
    loanFieldChangesCount,
    isValidateSubmitButtonEnabled,
  ]);

  const request = lockRequests.find(
    (req) => req.id === Session.get(ORIGIN_LOCKID),
  );

  const isActiveLock = lockRequests.some(
    ({ requestedStatus }) => requestedStatus === 'Locked',
  );

  const buttonsProps = {
    deny: {
      show: request?.requestedStatus === 'Requested',
      handler: () => setDenyLockModal(true),
    },
    denyActiveLock: {
      show: request?.requestedStatus !== 'Requested' && isActiveLock,
      handler: () => setDenyActiveLockModal(true),
    },
    cancel: {
      show: request?.requestedStatus !== 'Requested' && isActiveLock,
      handler: () => setCancelLockModal(true),
      disabled: isUpdateLockButtonDisabled,
    },
    lockConfirm: {
      show: true,
      handler: () =>
        dispatch(updateAndConfirmAction(setOpenLockAndConfirmModal) as any),
    },
  };

  const buttons = getFooterButtons(buttonsProps);
  const isBuySide = Session.get(IS_BUYSIDE);

  return (
    <>
      <CommentModal
        title="Deny Lock Request"
        show={denyLockModal}
        onHide={() => setDenyLockModal(false)}
        onConfirm={(comments) =>
          dispatch(denyLockRequestAction(comments) as any)
        }
      />
      <CommentModal
        title="Deny Lock"
        show={denyActiveLockModal}
        onHide={() => setDenyActiveLockModal(false)}
        onConfirm={(comments) =>
          dispatch(denyActiveLockAction(comments) as any)
        }
      />
      <CommentModal
        title="Cancel Lock"
        show={cancelLockModal}
        onHide={() => setCancelLockModal(false)}
        onConfirm={(comments) => dispatch(cancelLockAction(comments) as any)}
      />
      <DSModal
        centered
        isOpen={openLockAndConfirmModal}
        modalSubType={MODAL_SUB_TYPE_V2.SUCCESS}
        modalTitle="Success"
        modalType={MODAL_TYPE_V2.DECISION}
        confirmLabel={isBuySide ? 'Go to Sell Side' : 'Go to Buy Side'}
        onConfirm={() => {
          if (isBuySide) {
            Session.set(
              ORIGIN_TRANSACTION_TYPE,
              getAppConfigValue<string>('partner-ui.dataSourceBuyside'),
            );
            navigate(PATH_LOCK_SUMMARY);
          } else {
            Session.set(
              ORIGIN_TRANSACTION_TYPE,
              getAppConfigValue<string>('partner-ui.dataSourceSellside'),
            );
            navigate(PATH_LOCK_SUMMARY);
          }
        }}
        onReject={() => Common.closeApplication()}
        rejectLabel="Close ICE PPE"
        showRejectButton
        showClose={false}
        version={2}
      >
        <p>Pricing lock is confirmed.</p>
      </DSModal>
      <FooterButtons buttons={buttons} />
    </>
  );
};

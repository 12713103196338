import { DSControlledCheckbox } from '@elliemae/ds-controlled-form';
import { DSFormLayoutBlockItem } from '@elliemae/ds-form-layout-blocks';
import PropTypes from 'prop-types';
import { memo, useEffect, useMemo, useState } from 'react';
import { TruncatedLabel } from '../../WrapContainer/styled';
import { getValidator, isFunction } from '../utils/common';
import {
  useLoanDataFieldSelector,
  useSearchFormSelector,
} from '../utils/customHooks';

export const ControlledCheckboxForm = ({
  name,
  label,
  required,
  pathValue,
  checkedDefault,
  feedbackMessage,
  ...rest
}) => {
  const getFielValue = useLoanDataFieldSelector(pathValue) as any;
  const dependencies = useLoanDataFieldSelector(rest.dependencies);
  const extraDependencies = useSearchFormSelector(rest.extraDependencies);
  const [checked, setChecked] = useState(checkedDefault);
  const [validator, setValidator] = useState<any>({
    hasError: false,
    errorMessage: '',
    required,
  });

  const customDependencies = useMemo(
    () => ({ ...dependencies, ...extraDependencies }),
    [dependencies, extraDependencies],
  );

  const handleValidator = (value) => {
    const validatorValues = { value, required, name, customDependencies };
    const newValidator = getValidator({ ...validatorValues });
    if (newValidator?.errorMessage !== validator?.errorMessage) {
      setValidator(newValidator);
    }
  };

  useEffect(() => {
    setChecked(!!getFielValue.value);
    handleValidator(getFielValue.value);
  }, [getFielValue, customDependencies]);

  useEffect(() => {
    const { customValue } = rest;

    if (!customValue) return;

    const value = isFunction(customValue)
      ? customValue({
          value: getFielValue.value,
          pathValue,
          customDependencies,
        })
      : getFielValue.value;

    handleValidator(value);
    setChecked(value);
  }, [customDependencies, rest.initialValue, getFielValue]);

  const handleOnChange = (value) => {
    const { onChange } = rest;
    setChecked(value);
    if (onChange) onChange({ value, pathValue, customDependencies });
  };

  const handleDisabled = () => {
    const { customDisabled } = rest;
    if (typeof customDisabled === 'function') {
      return customDisabled({ value: checked, pathValue, customDependencies });
    }
    return customDisabled === true;
  };

  return (
    <DSFormLayoutBlockItem
      feedbackMessage={feedbackMessage || validator.feedbackMessage}
      validationMessage={validator.errorMessage}
      hasError={validator.hasError}
      inputID={`epps-field-id-${name}`}
    >
      <DSControlledCheckbox
        name={name}
        label={label}
        checked={checked}
        id={`epps-field-id1-${name}`}
        hasError={validator.hasError}
        labelText={<TruncatedLabel label={label} />}
        onChange={(value) => handleOnChange(value)}
        test="ControlledCheckboxForm"
        readOnly={handleDisabled()}
      />
    </DSFormLayoutBlockItem>
  );
};

ControlledCheckboxForm.defaultProps = {
  name: '',
  label: '',
  required: false,
  pathValue: '',
  checkedDefault: false,
  feedbackMessage: '',
};

ControlledCheckboxForm.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  pathValue: PropTypes.string,
  checkedDefault: PropTypes.bool,
  feedbackMessage: PropTypes.string,
};

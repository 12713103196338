import { DSTab, DSTabs } from '@elliemae/ds-tabs';
import { elementType } from 'prop-types';
import { useMemo } from 'react';
import {
  useCompareDataSelector,
  useLoanDataFieldSelector,
} from '../../../../data/useSelectors';
import { LoanFieldChangesView } from './components/LoanFieldChangesView';

export const Validate = ({ buySidePriceSection }) => {
  const { changeCounter: pricingChangesCount = 0 } =
    useCompareDataSelector() as any;
  const loanFieldChanges = useLoanDataFieldSelector(
    'additionalLockInfo.lockCompareResults',
    {},
  ) as any;
  const loanFieldChangesCount = useMemo(
    () => Object.values(loanFieldChanges).flat().length,
    [loanFieldChanges],
  );

  return (
    <DSTabs onlyRenderActiveTab>
      <DSTab
        tabId="PRICING_CHANGES"
        title={`Pricing Changes (${pricingChangesCount})`}
      >
        {buySidePriceSection}
      </DSTab>
      <DSTab
        tabId="LOAN_FIELD_CHANGES"
        title={`Loan Field Changes (${loanFieldChangesCount})`}
      >
        <LoanFieldChangesView />
      </DSTab>
    </DSTabs>
  );
};

Validate.propTypes = {
  buySidePriceSection: elementType.isRequired,
};

import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openGlobalErrorModalAction } from '../../../../../../data/globalErrorModal/actionCreators';
import { searchFormActions } from '../../../../../../store/searchForm';
import { ComboBoxForm, InputMaskForm } from '../../../../../components/Form';
import { setLoanDataValue } from '../../../../../components/Form/utils/common';
import { useLookupOptions } from '../../../../../components/Form/utils/customHooks';
import * as masks from '../../../../utils/constants';
import * as dependencies from '../../utils/dependencies';
import { hideAllButtonComboboxStyles } from '../../utils/handlers';

export const LoanTermsField = memo<any>(({ handleSetLoanDataValue }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const termRange = useLookupOptions('untransformedTermRange') || [];

  const dispatch = useDispatch();

  const handleLoanTermValue = (data) => {
    const { value, pathValue, options: lookupsLoanTerms } = data;

    if (value && value.length > 0) {
      dispatch(setLoanDataValue('', 'loanInformation.term') as any);
      const arrayHasHELOC = lookupsLoanTerms
        .filter((elem) => value.includes(elem.value))
        .some((elem) => elem.heloc);

      const arrayHasNonHELOC = lookupsLoanTerms
        .filter((elem) => value.includes(elem.value))
        .some((elem) => !elem.heloc);

      if (arrayHasHELOC && arrayHasNonHELOC) {
        setIsMenuOpen(false);
        value.pop();
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: 'HELOC cannot be selected with any other loan term.',
            onCloseCallback: () => setIsMenuOpen(true),
          }) as any,
        );
      }
      if (value.length > 8) {
        setIsMenuOpen(false);
        value.pop();
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: 'Please specify 8 or fewer loan terms.',
            onCloseCallback: () => setIsMenuOpen(true),
          }) as any,
        );
      }
    }

    handleSetLoanDataValue({ value, pathValue });
    dispatch(
      searchFormActions.setIsHELOC({ productType: value, lookupsLoanTerms }),
    );
  };

  const handleCustomTermChange = (data) => {
    const { value, pathValue } = data;
    dispatch(setLoanDataValue(value, pathValue) as any);
    if (
      !Number.isNaN(Number.parseInt(value, 10)) &&
      value >= 1 &&
      value <= 360
    ) {
      dispatch(setLoanDataValue([], 'productType') as any);
      const matchingLoanTerms = termRange.filter(
        (elem) => value >= elem.minTerm && value <= elem.maxTerm,
      );

      if (matchingLoanTerms && matchingLoanTerms.length === 0) {
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: 'No match found for Custom Term',
            onCloseCallback: () => setIsMenuOpen(true),
          }) as any,
        );

        dispatch(setLoanDataValue('', 'loanInformation.term') as any);
      }
    }
  };

  return (
    <>
      <ComboBoxForm
        isMulti
        maxOptions={8}
        label="Loan Terms"
        name="loanTerms"
        pathValue="productType"
        onChangeV2={handleLoanTermValue}
        componentsStyle={hideAllButtonComboboxStyles()}
        onMenuChange={{ onMenuChange: setIsMenuOpen }}
        isMenuOpen={{ isMenuOpen }}
        dependencies={dependencies.CUSTOM_TERM}
      />

      <InputMaskForm
        name="customTerm"
        label="Custom Term"
        maskOpts={masks.NO_DECIMAL_NO_PREFIX_NOT_NEGATIVE}
        maxLength={3}
        clearable
        pathValue="loanInformation.term"
        onBlur={handleCustomTermChange}
        onClearField={handleCustomTermChange}
        dependencies={dependencies.LOAN_TERM}
      />
    </>
  );
});

(LoanTermsField as any).defaultProps = {
  handleSetLoanDataValue: () => {},
};

(LoanTermsField as any).propTypes = {
  handleSetLoanDataValue: PropTypes.func,
};

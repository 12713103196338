import { useSelector, shallowEqual } from 'react-redux';
import {
  formValidationsSelector,
  loanDataFieldSelector,
  searchFormSelector,
  lookupsSelector,
  originalLoanInfoSelector,
} from '../../../../store/selectors';
import { isShallowEqual } from '../../../../common/utils/shared';

export const useFormValidationsSelector = (pathValue) =>
  useSelector(formValidationsSelector(pathValue), isShallowEqual);

export const useInitialValuesSelector = (pathValue) =>
  useSelector(formValidationsSelector(pathValue), isShallowEqual);

export const useSearchFormSelector = (pathValue) =>
  useSelector(searchFormSelector(pathValue), isShallowEqual);

export const useLoanDataFieldSelector = (pathValue?, defaultValue?: any) =>
  useSelector(loanDataFieldSelector(pathValue, defaultValue), isShallowEqual);

export const useOriginalLoanInfoSelector = (pathValue, defaultValue?: any) =>
  useSelector(originalLoanInfoSelector(pathValue, defaultValue), shallowEqual);

export const useLookupOptions = (lookupName) =>
  useSelector(lookupsSelector(lookupName));

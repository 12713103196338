import { isEmpty } from '../../shared';
import { inRange } from './helpers';

const financeInformation = {
  averageRepresentativeCreditScore: {
    fieldName: 'averageRepresentativeCreditScore',
    customValidator: (data, { dependencies }) => {
      const { averageRepresentativeCreditScore } = data;
      const { noCreditScores } = dependencies;
      const num = Number(averageRepresentativeCreditScore);
      if (
        (num < 300 || num > 850) &&
        averageRepresentativeCreditScore &&
        !noCreditScores
      ) {
        return { hasError: true, message: 'Between 300 and 850' };
      }
      return { hasError: false, message: null };
    },
  },
  representativeCreditScore: {
    fieldName: 'representativeCreditScore',
    customValidator: (data, { dependencies }) => {
      const { representativeCreditScore } = data;
      const { noCreditScores, borrowers } = dependencies;
      const result: { hasError: boolean; message: string | null } = {
        hasError: false,
        message: null,
      };
      if (
        representativeCreditScore === 0 &&
        !noCreditScores &&
        !isEmpty(borrowers)
      ) {
        result.hasError = true;
        result.message = 'Fico Score should be between 300 and 850';
      } else if (
        !isEmpty(borrowers) &&
        !noCreditScores &&
        !inRange(representativeCreditScore, 300, 850)
      ) {
        result.hasError = true;
        result.message = 'Fico Score should be between 300 and 850';
      }
      return result;
    },
  },
  employment: {
    fieldName: 'employment',
    message: 'Required field',
    allowedFalseyValues: [0],
    required: ({ required }) => required,
  },
  assets: {
    fieldName: 'assets',
    message: 'Required Field',
    required: ({ required }) => required,
    allowedFalseyValues: [0],
    customValidator: (data, { dependencies }) => {
      const { employment } = dependencies;
      const { assets } = data;
      const result: { hasError: boolean; message: string | null } = {
        hasError: false,
        message: null,
      };
      if (employment === 0 && assets !== 0) {
        result.hasError = true;
        result.message =
          'Must select No Asset for the given Employment Selection.';
      }
      return result;
    },
  },
  income: {
    fieldName: 'income',
    message: 'Required Field',
    required: ({ required }) => required,
    allowedFalseyValues: [0],
    customValidator: (data, { dependencies }) => {
      const { employment } = dependencies;
      const { income } = data;
      const result: { hasError: boolean; message: string | null } = {
        hasError: false,
        message: null,
      };
      if (employment === 0 && income !== 0) {
        result.hasError = true;
        result.message =
          'Must select No Income for the given Employment Selection.';
      }
      return result;
    },
  },
  liquidAsset: {
    fieldName: 'liquidAsset',
    min: {
      value: 0,
      message: 'Please enter Liquid Assets or 0',
    },
  },
  retirementAsset: {
    fieldName: 'retirementAsset',
    min: {
      value: 0,
      message: 'Please enter Retirement Assets or 0',
    },
  },
  anualIncome: {
    fieldName: 'anualIncome',
    min: {
      value: 0,
      message: 'Please enter Annual Income or 0',
    },
  },
  monthlyDebt: {
    fieldName: 'monthlyDebt',
    min: {
      value: 0,
      message: 'Please enter Monthly Debt or 0',
    },
  },
  nonQMDocLevel: {
    fieldName: 'nonQMDocLevel',
    required: ({ required }) => required,
    message: 'Required Field',
  },
};

export default financeInformation;

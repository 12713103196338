import { DataTable } from '@elliemae/ds-data-table';
import { DSTab, DSTabs, TabTypes } from '@elliemae/ds-tabs';
import { arrayOf, bool, shape } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectProduct } from '../../../../data/lockSummary/actions';
import { setLockedProgramData } from '../../../../data/origin/actions';
import {
  useLoanDataFieldSelector,
  useLockedProgramSelector,
  useLookup,
} from '../../../../data/useSelectors';
import { ProgramDetailsModal } from '../../../LockSummary/components/ProgramDetailsModal';
import { StyledDataTableWrapper } from '../../../global/styles/styled';
import { EligibleProductsToolbar } from '../ClassicGrid/EligibleProductsToolbar';
import { FILTERS_INITIAL_STATE } from '../ClassicGrid/constants';
import { getIneligibleGridColumns } from './utils/constants';
import { useIneligibleProductsGridHooks } from './utils/customHooks';

export const IneligibleProductsGrid = ({
  loanPrograms,
  loanLimits,
  refreshPrograms,
}) => {
  const [expandedRows, setExpandedRows] = useState({});
  const [programSlide, setProgramSlide] = useState(false);
  const actionRef = useRef<any>({});
  const [activeFilters, setActiveFilters] = useState(FILTERS_INITIAL_STATE);
  const clientsettings = useLookup('clientSettings', '');
  const loanTerms = (useLoanDataFieldSelector('productType', []) ||
    []) as any[];
  const lookupsLoanTerms = useLookup('loanTerms') as any[];
  let loanTermsTabs: any[] = [];
  if (Array.isArray(loanTerms) && lookupsLoanTerms) {
    loanTermsTabs = lookupsLoanTerms.filter((obj) =>
      loanTerms.includes(obj.value),
    );
    loanTermsTabs.unshift({ label: 'All' });
  }
  const { focusIneligible, programID } = useLockedProgramSelector();
  const dispatch = useDispatch();

  const handleFilterChange = (filterName, value) => {
    setActiveFilters((prevState) => ({ ...prevState, [filterName]: value }));
  };

  const { newRows } = useIneligibleProductsGridHooks(
    loanPrograms,
    loanLimits,
    activeFilters,
  ) as any;

  useEffect(() => {
    if (focusIneligible && newRows?.length !== 0) {
      if (actionRef?.current?.scrollToIndex) {
        const programIndex = newRows.findIndex(
          (elem) => elem?.programID === programID,
        );
        actionRef.current.scrollToIndex(programIndex, { align: 'center' });
        setExpandedRows((prevExpandedRows) => {
          const newExpanded = { ...prevExpandedRows };
          newExpanded[programIndex] = true;
          return newExpanded;
        });
        dispatch(setLockedProgramData({ focusIneligible: false }));
      }
    }
  }, [focusIneligible, actionRef, newRows]);

  if (clientsettings !== '') {
    return (
      <>
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
          <DSTabs type={TabTypes.SUBTABS}>
            {loanTermsTabs.map((tab, idx) => (
              <DSTab
                key={tab.label.concat(idx)}
                tabId={`tab${idx + 1}`}
                title={tab.label}
                onClick={() => {
                  handleFilterChange('term', tab);
                }}
              />
            ))}
          </DSTabs>
        </div>
        <div style={{ height: 'calc(100% - 100px)' }}>
          <EligibleProductsToolbar
            activeFilters={activeFilters}
            onFilterChange={handleFilterChange}
            refreshPrograms={refreshPrograms}
            showTarget={false}
            showFlagged={false}
          />
          <ProgramDetailsModal
            isSlide
            isOpen={programSlide}
            toggleModalSlide={() => setProgramSlide(false)}
          />
          <StyledDataTableWrapper>
            <DataTable
              uniqueRowAccessor="programID"
              actionRef={actionRef}
              height="100%"
              paginated
              isExpandable
              data={newRows}
              expandedRows={expandedRows}
              onRowExpand={setExpandedRows}
              columns={getIneligibleGridColumns()}
              cellRendererProps={{
                onClickProgramName: (rowData) => {
                  dispatch(selectProduct(rowData));
                  setProgramSlide(true);
                },
              }}
              colsLayoutStyle="auto"
              noResultsMessage="No Results Found"
              noResultsSecondaryMessage="You do not have any Programs at this time."
              rowKey="programID"
            />
          </StyledDataTableWrapper>
        </div>
      </>
    );
  }
  return null;
};

IneligibleProductsGrid.defaultProps = {
  loanPrograms: [],
  loanLimits: [],
  refreshPrograms: false,
};

IneligibleProductsGrid.propTypes = {
  loanPrograms: arrayOf(shape({})),
  loanLimits: arrayOf(shape({})),
  refreshPrograms: bool,
};

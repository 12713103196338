export const DOCUMENTATION_LEVEL = [
  {
    path: '',
    values: ['documentationLevel'],
  },
  {
    path: 'loanInformation',
    values: ['selfEmployedBorrower', 'selfEmployedCoBorrower'],
  },
];

export const CREDIT_SCORE = [
  {
    path: 'borrowerFinancialHistory',
    values: ['noCreditScores'],
  },
  {
    path: '',
    values: ['borrowers'],
  },
];

export const AVG_REP_CREDIT_SCORE = [
  {
    path: 'borrowerFinancialHistory',
    values: ['noCreditScores'],
  },
];

export const NOD_TYPE = [
  {
    path: 'borrowerFinancialHistory',
    values: ['noticeOfDefaultForeClosure'],
  },
];

export const BANKRUPCY_TYPE = [
  {
    path: 'borrowerFinancialHistory',
    values: ['bankruptcyInMonths'],
  },
];

export const EXTRA_BORROWERS = [
  {
    path: 'initialValues',
    values: ['initialBorrowers'],
  },
  {
    path: 'errorForm',
    values: ['errorFilters'],
  },
];

export const EXTRA_FIRST_TIME_HOME_BUYER = [
  {
    path: 'formValidations',
    values: ['isRefinance'],
  },
];

import { select, put, takeLatest } from 'redux-saga/effects';
import { openGlobalErrorModalAction } from '../../data/globalErrorModal/actionCreators';
import {
  getGeocodes,
  getAllLookups,
} from '../../common/services/searchFormServices';
import Common from '../../common/services/Common';
import { isEmpty } from '../../common/utils/shared';
import { searchFormActions } from '../searchForm';
import { openLoader, closeLoader } from '../../data/screenLoader/actions';
import { ERROR_MESSAGES } from '../../common/utils/constants/ErrorMessages';
import { lookupsActions } from './index';
import { docTypesOptions, customOptions } from './utils/constants';
import { lookupsSelector, loanIdSelector } from '../selectors';
import {
  generateComboboxOptions,
  generateDocTypesComboboxOptions,
  generateOfficeUsersComboboxOptionsWithItemLayout,
} from './utils/helper';

// ----- WORKERS -------------------------------------------------------

// TODO it must be removed once qualificationAction is refactorized P1
function* errorHandling(dispatch, err) {
  const {
    PS095: { errorMessage },
  } = ERROR_MESSAGES;

  dispatch(
    openGlobalErrorModalAction({
      reCallModal: err?.message !== 'PS095',
      reCallModalFunc: () =>
        dispatch(lookupsActions.getLookupsDataAction({ dispatch } as any)),
      reCallModalFinalFunc: () => Common.closeApplication(),
      confirmLabel: err?.message === 'PS095' ? 'Close ICE PPE' : 'Ok',
      ...(err?.message === 'PS095' && {
        errorMessage,
        onCloseCallback: () => Common.closeApplication(),
      }),
    }),
  );
}

function* getGeocodesOptions({ payload: { dispatch } }) {
  try {
    const loanId = yield select(loanIdSelector());
    yield put(openLoader(''));
    if (loanId) {
      const geocodes = yield getGeocodes(loanId);
      yield put(lookupsActions.setLookupsSuccess({ geocodes }));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    yield errorHandling(dispatch, e);
  } finally {
    yield put(closeLoader());
  }
}

function* getLookupsOptions({ payload: { dispatch } }) {
  try {
    const lookupsAPIs = yield select(lookupsSelector());
    if (isEmpty(lookupsAPIs)) {
      yield put(openLoader(''));
      const mappingLookups: any = {};
      const untransformedMappingLookups: any = {};
      const lookups: any = {
        ...(yield getAllLookups()),
        ...customOptions,
      };
      Object.entries(lookups).forEach(([key, value]) => {
        if (key === 'officeUsers') {
          mappingLookups.transformedOfficeUsers =
            generateOfficeUsersComboboxOptionsWithItemLayout(key, value as any);
        }
        mappingLookups[key] = generateComboboxOptions(key, value as any);
      });
      Object.entries(docTypesOptions).forEach(([key, options]) => {
        const { documentationTypes } = lookups;
        const docTypesOpts = { key, options, documentationTypes };
        mappingLookups[key] = generateDocTypesComboboxOptions(docTypesOpts);
      });
      untransformedMappingLookups.untransformedLoanUsage = lookups.loanUsage;
      untransformedMappingLookups.untransformedPropertyTypes =
        lookups.propertyTypes;
      untransformedMappingLookups.untransformedPropertyUse =
        lookups.propertyUse;
      untransformedMappingLookups.untransformedTermRange = lookups.termRange;
      yield put(lookupsActions.setLookupsSuccess(untransformedMappingLookups));
      yield put(lookupsActions.setLookupsSuccess(mappingLookups));
      yield put(searchFormActions.getFormValidationsAction());
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    yield put(closeLoader());
    yield errorHandling(dispatch, e);
  }
}

// ---------------------------------------------------------------------
export default function* watchers() {
  yield takeLatest(
    lookupsActions.getLookupsDataAction.type as any,
    getLookupsOptions,
  );
  yield takeLatest(
    lookupsActions.getGeocodesDataAction.type as any,
    getGeocodesOptions,
  );
}

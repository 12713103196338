import { DSAccordion, DSAccordionItem } from '@elliemae/ds-accordion';
import { Grid } from '@elliemae/ds-grid';
import { DSHeader } from '@elliemae/ds-header';
import { DSSeparator } from '@elliemae/ds-separator';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { searchFormActions } from '../../../../store/searchForm';
import {
  ComboBoxForm,
  GridForm,
  InputMaskForm,
} from '../../../components/Form';
import { DatePickerForm } from '../../../components/Form/DatePickerForm';
import { setLoanDataValue } from '../../../components/Form/utils/common';
import * as masks from '../../utils/constants';
import { LoanTermsField } from './components/LoanTermsField';
import * as dependencies from './utils/dependencies';
import * as handlers from './utils/handlers';

export const ProductInformationForm = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(null);
  const handleSetLoanDataValue = useCallback(({ value, pathValue }) => {
    const parseValue = ['', undefined, 'none'].includes(value) ? null : value;
    dispatch(setLoanDataValue(parseValue, pathValue) as any);
  }, []);

  return (
    <>
      <DSSeparator
        mb="16px"
        mt="8px"
        orientation="horizontal"
        type="non-form"
        position="initial"
      />
      <DSHeader
        text="Product Information"
        fontSize={16}
        fontWeight="bold"
        lineHeight={1}
        mb="xs"
      />
      <Grid
        cols={['25%', '15%', '25%', '25%']}
        gutter="xs"
        alignItems="flex-start"
      >
        <LoanTermsField
          handleSetLoanDataValue={handleSetLoanDataValue as any}
        />

        <ComboBoxForm
          isMulti
          name="standardProducts"
          label="Standard Products"
          pathValue="standardProducts"
          onChangeV2={(data) => {
            handleSetLoanDataValue(data);
            dispatch(searchFormActions.setIsGovie(data.value));
            dispatch(searchFormActions.setIsVA(data.value));
          }}
          dependencies={dependencies.STANDAR_PRODUCTS}
        />
        <Grid cols={2} gutter="xs" alignItems="flex-start">
          <Grid span={1}>
            <ComboBoxForm
              required
              isNonClearable
              name="targetTypes"
              label="Value"
              pathValue="loanInformation.targetLabel"
              onChangeV2={({ value, pathValue }) => {
                handleSetLoanDataValue({ value, pathValue });
                const targetPriceValue = value === 'Price' ? 100 : null;
                dispatch(
                  setLoanDataValue(
                    targetPriceValue,
                    'loanInformation.target',
                  ) as any,
                );
              }}
            />
          </Grid>
          <GridForm
            span={1}
            hidden={handlers.handleTargetPriceField}
            dependencies={dependencies.TARGET_LABEL}
          >
            <InputMaskForm
              required
              clearable
              maxLength={12}
              maskOpts={masks.PERCENT_VALUES}
              name="targetValue"
              pathValue="loanInformation.target"
              label="Target Value"
              onBlur={handleSetLoanDataValue}
              dependencies={dependencies.TARGET_LABEL}
            />
          </GridForm>
          <GridForm
            span={1}
            hidden={handlers.handleTargetRateField}
            dependencies={dependencies.TARGET_LABEL}
          >
            <InputMaskForm
              required
              clearable
              maxLength={12}
              maskOpts={masks.PERCENT_VALUES}
              maskType="PERCENT"
              name="targetValue"
              pathValue="loanInformation.target"
              label="Target Value"
              onBlur={handleSetLoanDataValue}
              dependencies={dependencies.TARGET_LABEL}
            />
          </GridForm>
        </Grid>
        <DatePickerForm
          name="fhaCaseNumberDate"
          labelText="FHA Case Number Date"
          pathValue="loanInformation.fhaCaseNumberDate"
          screenReaderInputMessage="FHA Case Number Date"
          onChange={({ value, pathValue }) => {
            const parsedValue = moment(value).format('MM/DD/YYYY');
            handleSetLoanDataValue({ value: parsedValue, pathValue });
          }}
        />
        <ComboBoxForm
          isMulti
          name="specialProducts"
          label="Special Products"
          pathValue="specialProducts"
          dependencies={dependencies.SPECIAL_PRODUCTS}
          onChangeV2={(data) => {
            handleSetLoanDataValue(data);
            dispatch(searchFormActions.setIsIRRRL(data.value));
          }}
        />
      </Grid>
      <Grid>
        <pre>{'\n'}</pre>
      </Grid>

      <DSAccordion activeValue={active} onChange={setActive as any} span={3}>
        <DSAccordionItem
          title={active === 0 ? 'Hide Detailed Entry' : 'Open Detailed Entry'}
          key={0}
          value={0}
        >
          <Grid
            cols={['25%', '25%', '25%', '25%']}
            gutter="xs"
            alignItems="flex-start"
          >
            <ComboBoxForm
              isMulti
              required
              name="productOptions"
              label="Product Options"
              pathValue="productOptions"
              onChangeV2={handleSetLoanDataValue}
            />
            <ComboBoxForm
              isNonClearable
              name="prepayPenaltyTerms"
              label="Pre-Payment Penalty"
              customValue={({ value }) => (value === null ? 'none' : value)}
              pathValue="loanInformation.prepaymentPenaltyTerm"
              onChangeV2={handleSetLoanDataValue}
            />
            <ComboBoxForm
              isNonClearable
              name="compensationTypes"
              label="Compensation Type"
              customValue={handlers.compensationValue}
              pathValue="compensation.model"
              onChangeV2={({ value, pathValue }) => {
                const parseValue = handlers.parseCompensationValue(value);
                handleSetLoanDataValue({ value: parseValue, pathValue });
              }}
            />
            <pre>{'\n'}</pre>
            <ComboBoxForm
              isNonClearable
              name="buydownType"
              label="Temporary Buydown Type"
              pathValue="buydownType"
              customDisabled
              onChangeV2={handleSetLoanDataValue}
              customValue={({ value }) => (value === null ? 'none' : value)}
              feedbackMessage="This must be updated in your loan origination system and resubmitted to ICE PPE"
            />
            <GridForm
              span={1}
              hidden={handlers.handleIsBuyDownType}
              dependencies={dependencies.BUYDOWN_TYPE}
            >
              <ComboBoxForm
                required
                isNonClearable
                name="buydownContributorType"
                label="Temporary Buydown Contributor"
                pathValue="buydownContributor"
                customDisabled
                onChangeV2={handleSetLoanDataValue}
                customValue={({ value }) => (value === null ? 'none' : value)}
                feedbackMessage="This must be updated in your loan origination system and resubmitted to ICE PPE"
                dependencies={dependencies.BUYDOWN_TYPE}
              />
            </GridForm>
          </Grid>
        </DSAccordionItem>
      </DSAccordion>
    </>
  );
};

import { jsx } from "react/jsx-runtime";
import { Grid } from "@elliemae/ds-grid";
import { useState, useEffect } from "react";
import { DSControlledCheckbox } from "@elliemae/ds-form-checkbox";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { useCheckboxFieldUpdateLogic } from "./utils/fieldUpdateLogic";
import { evaluateRuleById } from "./utils/utils";
const Checkbox = ({
  renderedField,
  schemaForm,
  rules,
  name,
  label,
  required,
  pathValue,
  feedbackMessage,
  ui,
  visible,
  disabled,
  disableCheck,
  visibleCheck,
  preview
}) => {
  const checkboxUpdateLogic = useCheckboxFieldUpdateLogic();
  const [isVisible, setIsVisible] = useState(visible);
  const [isDisabled, setIsDisabled] = useState(disabled);
  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);
  useEffect(() => {
    const updateVisibility = () => {
      if (visibleCheck?.handler) {
        setIsVisible(
          evaluateRuleById(
            visibleCheck.handler,
            schemaForm.getValues(),
            rules,
            preview
          ) ? !visible : visible
        );
      }
    };
    const updateDisabledState = () => {
      if (disableCheck?.handler) {
        setIsDisabled(
          evaluateRuleById(
            disableCheck.handler,
            schemaForm.getValues(),
            rules,
            preview
          ) ? !disabled : disabled
        );
      }
    };
    const subscription = schemaForm.watch(() => {
      updateVisibility();
      updateDisabledState();
    });
    updateVisibility();
    updateDisabledState();
    return () => subscription.unsubscribe();
  }, [schemaForm]);
  return /* @__PURE__ */ jsx(
    Grid,
    {
      mt: "2px",
      gutter: "xs",
      height: "100%",
      wrap: "nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      span: ui.col_size ?? 2,
      cols: ["auto", "auto", "auto", "auto"],
      style: { display: isVisible ? "block" : "none" },
      children: /* @__PURE__ */ jsx(
        DSFormLayoutBlockItem,
        {
          inputID: `epps-field-id-${name}`,
          label: "",
          required,
          feedbackMessage,
          children: /* @__PURE__ */ jsx(
            DSControlledCheckbox,
            {
              ...renderedField,
              checked: renderedField?.value ?? false,
              name,
              label,
              id: pathValue,
              onChange: checkboxUpdateLogic(renderedField, schemaForm),
              disabed: isDisabled
            }
          )
        }
      )
    }
  );
};
export {
  Checkbox
};

export const DOCUMENTATION_LEVEL = [
  {
    path: '',
    values: ['documentationLevel'],
  },
  {
    path: 'loanInformation',
    values: ['selfEmployedBorrower', 'selfEmployedCoBorrower'],
  },
];

export const ASSET = [
  {
    path: '',
    values: ['employment'],
  },
];

export const INCOME = [
  {
    path: '',
    values: ['employment'],
  },
];

export const EXTRA_NONQM_DOCLEVEL = [
  {
    path: '',
    values: ['income', 'standardProducts'],
  },
];

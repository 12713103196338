import { BANNER_TYPES, DSBanner } from '@elliemae/ds-banner';
import { get } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Session, {
  IS_BUYSIDE,
  IS_LOCK_EXTENSION,
} from '../../../../common/services/Session';
import {
  useLoanDataFieldSelector,
  useLookup,
} from '../../../../data/useSelectors';
import { PATH_SEARCH_RESULTS } from '../../../../route/constants';
import { HISTORICAL_PRICING_TEXT } from '../HeaderMenu/utils/constants';

export const PricingNotificationHeader = () => {
  const [isOpen, setOpen] = useState(false);
  const [showMessage, setShowMessage] = useState(true);
  const { effectiveDate = '' } = useLoanDataFieldSelector(
    'relockRequest',
    {},
  ) as any;
  const { tabId } = useSelector(({ landing }) => landing);
  const clientsettings = useLookup('clientSettings', '');

  useEffect(() => {
    const momentEffectiveDate = moment.tz(
      effectiveDate,
      'YYYY-MM-DDTHH:mm:ss a z',
      'America/New_York',
    );
    setOpen(
      tabId === PATH_SEARCH_RESULTS &&
        !!effectiveDate &&
        momentEffectiveDate.isBefore(moment()),
    );
    if (
      Session.get(IS_BUYSIDE) === true &&
      Session.get(IS_LOCK_EXTENSION) === false &&
      get(clientsettings, '[0].options.isBuySideHistorical') === false
    ) {
      setShowMessage(false);
    }
  }, [effectiveDate, tabId, showMessage]);

  const getEffectiveDate = () =>
    moment.tz(effectiveDate, 'America/New_York').format('MM-DD-yyyy hh:mm A');

  return (
    <DSBanner
      body={`Effective Date ${getEffectiveDate()} EST`}
      isOpen={showMessage && isOpen}
      label={HISTORICAL_PRICING_TEXT}
      onClose={() => setOpen(false)}
      type={BANNER_TYPES.WARNING}
      data-testid="PricingNotificationHeader-all-components"
    />
  );
};

import { Grid } from '@elliemae/ds-grid';
import { DSTooltipV2 } from '@elliemae/ds-tooltip';
import { arrayOf, func, node, oneOf, string } from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { TOOLTIP_MESSAGES } from '../../../../common/utils/constants/TooltipMessages';
import {
  useLoanDataFieldSelector,
  useOriginalLoanInfoSelector,
  useSearchFormSelector,
} from '../utils/customHooks';

export const GridForm = ({ children, span, ...rest }) => {
  const [hiddenField, setHiddenField] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const dependencies = useLoanDataFieldSelector(rest.dependencies);
  const extraDependencies = useSearchFormSelector(rest.extraDependencies);
  const originalLoanInfoDependencies = useOriginalLoanInfoSelector(
    rest.originalLoanInfoDependencies,
  );

  const customDependencies = useMemo(
    () => ({
      ...dependencies,
      ...extraDependencies,
      ...originalLoanInfoDependencies,
    }),
    [dependencies, extraDependencies, originalLoanInfoDependencies],
  );

  useEffect(() => {
    const { hidden, tooltip } = rest;
    if (![null, undefined].includes(hidden)) {
      const isHidden =
        hidden && typeof hidden === 'function'
          ? hidden({ customDependencies })
          : hidden;
      setHiddenField(isHidden);
    }
    if (tooltip) {
      const isTooltip =
        tooltip && typeof tooltip === 'function'
          ? tooltip({ customDependencies })
          : tooltip;
      setShowTooltip(isTooltip);
    }
  }, [customDependencies]);

  const gridSpan = typeof span === 'function' ? span(customDependencies) : span;

  const renderGridForm = useMemo(() => {
    if (showTooltip) {
      return (
        <Grid span={gridSpan} {...rest}>
          <DSTooltipV2
            title={TOOLTIP_MESSAGES.initialFormValue}
            triggerComponent={children}
          />
        </Grid>
      );
    }
    return (
      <Grid span={gridSpan} {...rest}>
        {children}
      </Grid>
    );
  }, [children, showTooltip, rest]);

  return !hiddenField ? renderGridForm : null;
};

GridForm.defaultProps = {
  span: '',
};

GridForm.propTypes = {
  children: arrayOf(node).isRequired,
  span: oneOf([func, string]),
};

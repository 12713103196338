export const isFalsey = (value) => [null, undefined, ''].includes(value);

export const inRange = (a, b, c) =>
  !Number.isNaN(parseFloat(a)) && a >= b && a <= c;

export const isString = (value) => typeof value === 'string';

export const numberFieldSanitizer = (v) =>
  isString(v) ? parseFloat(v.replace(/[$,]/g, '')) : v;

export const minmaxValidation = {
  value: [1, 999999999],
  message: (_, value) => {
    if (value < 1) return 'Value must be greater than 0';
    if (value > 999999999) return 'Value must be lower than 999,999,999';
    return '';
  },
};

export const minmaxValidation1 = {
  value: [1, 999999999],
  rangeInclusive: true,
  message: (_, value) => {
    if (value < 1) return 'Value must be greater than 0';
    if (value > 999999999) return 'Value must be lower than 999,999,999';
    return '';
  },
};

export const minmaxValidation2 = {
  value: [0, 999999999],
  message: (_, value) => {
    if (value < 1) return 'Value must be greater than 0';
    if (value > 999999999) return 'Value must be lower than 999,999,999';
    return '';
  },
};

export const customMinmaxValidation = (range = [1, 99999999]) => ({
  value: [range[0], range[1]],
  message: (_, value) => {
    if (value < range[0]) return `Value must be greater than ${range[0] - 1}`;
    if (value > range[1]) return `Value must be lower than ${range[1] - 1}`;
    return '';
  },
});

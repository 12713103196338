import { CloseSmall } from '@elliemae/ds-icons';
import { DSToolbar, ToolbarGroup, ToolbarItem } from '@elliemae/ds-toolbar';
import { bool, func, string } from 'prop-types';
import { useUserData } from '../../../../../data/useSelectors';
import {
  useLoanDataFieldSelector as useLoanDataFieldSelectorHook,
  useLookupOptions,
} from '../../../Form/utils/customHooks';
import { LockDeskStatus } from '../LockDeskStatus';
import { HELP_TEXT, PRICING_TEXT } from '../utils/constants';
import { StyledDebugInfo, StyledSlash } from '../utils/styled';

export const HeaderToolbar = ({
  isSmartClient,
  handleOpenModal,
  loanId,
}: Partial<{
  isSmartClient: boolean;
  handleOpenModal: any;
  loanId: string;
}>) => {
  const { firstName, lastName } = useUserData();
  const eppsUserName = useLoanDataFieldSelectorHook('eppsUserName') as any;
  const officersOptions = useLookupOptions('transformedOfficeUsers');
  const loanOfficer =
    eppsUserName && officersOptions
      ? officersOptions.find((option) => option.login === eppsUserName.value)
      : null;
  const displayName = loanOfficer?.label ?? `${firstName} ${lastName}`;

  const isVisibleLockDesk = false; // APPLY_EPPS_LOCKDESK_SETTINGS && isBuySide && isValidChannel;

  if (isSmartClient) {
    return (
      <DSToolbar size="compact" withDepth={false} autoFocusOnMount={false}>
        <ToolbarGroup>
          {isVisibleLockDesk && <LockDeskStatus />}
          <StyledDebugInfo data-testid="HeaderToolbar-all-components">
            <a
              href="https://help.icemortgagetechnology.com/documentation/EPPS-Web/Content/Welcome.htm"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              {HELP_TEXT}
            </a>
          </StyledDebugInfo>
        </ToolbarGroup>
      </DSToolbar>
    );
  }
  return (
    <DSToolbar size="compact" withDepth={false} autoFocusOnMount={false}>
      <ToolbarGroup>
        {isVisibleLockDesk && (
          <>
            <LockDeskStatus />
            <div style={{ marginLeft: '16px' }} />
            <StyledSlash />
          </>
        )}
        <StyledDebugInfo data-testid="HeaderToolbar-all-components">
          <a
            href="https://help.icemortgagetechnology.com/documentation/EPPS-Web/Content/Welcome.htm"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
          >
            {HELP_TEXT}
          </a>
        </StyledDebugInfo>
        {firstName && lastName && (
          <>
            <StyledDebugInfo data-testid="HeaderToolbar-all-components">
              {displayName}
            </StyledDebugInfo>
            <StyledSlash />
          </>
        )}
        {loanId && (
          <>
            <StyledDebugInfo data-testid="HeaderToolbar-all-components">
              {PRICING_TEXT + loanId}
            </StyledDebugInfo>
            <StyledSlash />
          </>
        )}
        <ToolbarItem
          icon={<CloseSmall />}
          onClick={handleOpenModal}
          style={{ marginLeft: '7px', paddingTop: '6px' }}
        />
      </ToolbarGroup>
    </DSToolbar>
  );
};

HeaderToolbar.defaultProps = {
  loanId: '',
  isSmartClient: false,
  handleOpenModal: () => {},
};

HeaderToolbar.propTypes = {
  loanId: string,
  isSmartClient: bool,
  handleOpenModal: func,
};

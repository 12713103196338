import { ExportExcel, Print, TimerClock } from '@elliemae/ds-icons';
import { DSPageHeader } from '@elliemae/ds-page-header';
import { DSToolbar, ToolbarItem } from '@elliemae/ds-toolbar';
import { DSTooltipV2, tooltipPositions } from '@elliemae/ds-tooltip';
import { func, node } from 'prop-types';
import { useEffect, useState } from 'react';
import {
  getRateSheetDocument,
  validateRateDocInstance,
} from '../../../../common/services/lockSummaryServices';
import api from '../../../../common/utils/api';
import {
  useLoanDataFieldSelector,
  useRateSelector,
  useRateSelectorLien,
  useSelectedProductSelector,
} from '../../../../data/useSelectors';
import { RateAlert } from '../RateAlertModal/RateAlertContainer';

export const HeaderDetailsToolbar = ({ onPrintClick, onRateAlertClick }) => {
  const { rateDocInstanceID } = useSelectedProductSelector();
  const [isExportExcel, setIsExportExcel] = useState(false);

  useEffect(() => {
    // TODO we should check this. The return of validateRateDocInstance is not a Promise
    validateRateDocInstance(rateDocInstanceID).then((data) => {
      setIsExportExcel(data);
    });
  }, []);

  return (
    <DSToolbar
      withDepth={false}
      containerProps={{ style: { padding: 0 } }}
      autoFocusOnMount={false}
    >
      <DSTooltipV2
        placement={tooltipPositions.BOTTOM}
        title="Rate Alert"
        triggerComponent={
          <ToolbarItem icon={<TimerClock />} onClick={onRateAlertClick} />
        }
      />
      <DSTooltipV2
        placement={tooltipPositions.BOTTOM}
        title="Print"
        triggerComponent={
          <ToolbarItem icon={<Print />} onClick={onPrintClick} />
        }
      />
      <DSTooltipV2
        placement={tooltipPositions.BOTTOM}
        title="Download Rate Sheet"
        triggerComponent={
          <ToolbarItem
            style={{ display: isExportExcel ? 'block' : 'none' }}
            icon={<ExportExcel />}
            onClick={async () => {
              getRateSheetDocument(rateDocInstanceID);
            }}
          />
        }
      />
    </DSToolbar>
  );
};

HeaderDetailsToolbar.propTypes = {
  onPrintClick: func.isRequired,
  onRateAlertClick: func.isRequired,
};

const Iframe = ({ content }) => {
  const writeHTML = (frame) => {
    if (!frame) {
      return;
    }
    const doc = frame.contentDocument;
    doc.open();
    doc.write(content);
    doc.close();
  };
  return (
    <div style={{ display: 'none' }}>
      <iframe
        title="Print"
        id="print-doc"
        src="about:blank"
        scrolling="no"
        frameBorder="0"
        ref={writeHTML}
      />
    </div>
  );
};

Iframe.propTypes = { content: node.isRequired };

export const SummaryHeader = () => {
  const rateSelector = useRateSelector() as any;
  const requestLockStatus = useLoanDataFieldSelector('requestLockStatus', -1);
  const { programName, userLender } = useRateSelectorLien(
    ['programName', 'userLender'],
    '',
  ) as any;
  const programNameValue = programName ?? '';
  const userLenderValue = userLender ?? '';
  const [documentHTML, setDocumentHTML] = useState('');
  const [isRequestedModalOpen, setisRequestedModalOpen] = useState(false);

  const el = document.createElement('textarea');
  el.innerHTML = documentHTML;

  // Empty if 0 or 3 (No Status, and Expired)
  let lockStatusStr = '';
  if (Number(requestLockStatus) === 1) {
    lockStatusStr = 'Lock Request:'; // 1 = Not Locked
  } else if (Number(requestLockStatus) === 2) {
    lockStatusStr = 'Lock Update:'; // 2 = Locked - Active Lock
  } else if (Number(requestLockStatus) === 4) {
    lockStatusStr = 'Re-Lock:'; // 4 = Cancelled
  }

  const handlePrintClick = async () => {
    const response = await api.getLockSummaryDocument({
      rateSelectorResponse: rateSelector,
      comments: rateSelector?.loan?.comments || '',
    });

    setDocumentHTML(response.body);
    const iframe = document.getElementById('print-doc') as HTMLIFrameElement;
    iframe.focus();
    iframe.contentWindow?.print?.();
  };

  const handleRateAlertClick = () => {
    setisRequestedModalOpen(true);
  };

  const handleRateAlertCancel = () => {
    setisRequestedModalOpen(false);
  };

  const title = `${lockStatusStr} ${userLenderValue} ${programNameValue}`;

  return (
    <>
      <RateAlert
        isOpen={isRequestedModalOpen}
        handleCancel={handleRateAlertCancel}
      />
      <DSPageHeader
        renderToolbar={() => (
          <HeaderDetailsToolbar
            isPrintButton
            onPrintClick={handlePrintClick}
            onRateAlertClick={handleRateAlertClick}
          />
        )}
        title={title}
      />
      <Iframe content={el.value} />
    </>
  );
};

import { jsx } from "react/jsx-runtime";
import { Grid } from "@elliemae/ds-grid";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { useState, useEffect } from "react";
import {
  DSControlledDateTimePicker,
  CONTROLLED_DATE_TIME_PICKER_TYPES
} from "@elliemae/ds-form-date-time-picker";
import { evaluateRuleById, getDefaultFocusedDate } from "./utils/utils";
const Date = ({
  renderedField,
  schemaForm,
  rules,
  name,
  label,
  required,
  pathValue,
  feedbackMessage,
  ui,
  visible,
  disabled,
  disableCheck,
  visibleCheck,
  preview
}) => {
  const [isVisible, setIsVisible] = useState(visible);
  const [isDisabled, setIsDisabled] = useState(disabled);
  const [localDateTimeValue, setLocalDateTimeValue] = useState("");
  const [localDateValue, setLocalDateValue] = useState("");
  const [localTimeValue, setLocalTimeValue] = useState("");
  const handleOnDateChange = (newDate) => {
    const strDateTime = `${newDate} ${localTimeValue}`;
    setLocalDateValue(strDateTime);
  };
  const handleOnDateOnlyChange = (newDate) => {
    const strDateTime = `${newDate}`;
    setLocalDateValue(strDateTime);
  };
  const handleOnTimeChange = (newTime) => {
    const strDateTime = `${localDateValue} ${newTime}`;
    setLocalTimeValue(strDateTime);
  };
  const handleOnDateTimeChange = (newDateTime) => {
    const [prevDate] = localDateTimeValue.split(" ");
    const [newDate, newTime] = newDateTime.split(" ");
    const isDefaultTime = newDate && prevDate !== newDate && !newDate.includes("_") && !newTime.includes("12:00 AM");
    const actualDateTime = isDefaultTime ? `${newDate} 12:00 AM` : newDateTime;
    setLocalDateTimeValue(actualDateTime);
  };
  const isFullDateTime = ui?.type === CONTROLLED_DATE_TIME_PICKER_TYPES.DATE_TIME.FULL;
  const dateTimeProps = isFullDateTime ? {
    dateTime: localDateTimeValue,
    onDateChange: (newDate) => {
      handleOnDateChange(newDate);
      renderedField.onChange(localDateTimeValue);
    },
    onTimeChange: (newTime) => {
      handleOnTimeChange(newTime);
      renderedField.onChange(localDateTimeValue);
    },
    onDateTimeChange: (newDateTime) => {
      handleOnDateTimeChange(newDateTime);
      renderedField.onChange(localDateTimeValue);
    }
  } : {
    date: localDateValue,
    onDateChange: (newDate) => {
      handleOnDateOnlyChange(newDate);
      renderedField.onChange(localDateValue);
    }
  };
  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);
  useEffect(() => {
    const updateVisibility = () => {
      if (visibleCheck?.handler) {
        setIsVisible(
          evaluateRuleById(
            visibleCheck.handler,
            schemaForm.getValues(),
            rules,
            preview
          ) ? !visible : visible
        );
      }
    };
    const updateDisabledState = () => {
      if (disableCheck?.handler) {
        setIsDisabled(
          evaluateRuleById(
            disableCheck.handler,
            schemaForm.getValues(),
            rules,
            preview
          ) ? !disabled : disabled
        );
      }
    };
    const subscription = schemaForm.watch(() => {
      updateVisibility();
      updateDisabledState();
    });
    updateVisibility();
    updateDisabledState();
    return () => subscription.unsubscribe();
  }, [schemaForm]);
  return /* @__PURE__ */ jsx(Grid, { span: ui.col_size, style: { display: isVisible ? "block" : "none" }, children: /* @__PURE__ */ jsx(
    DSFormLayoutBlockItem,
    {
      inputID: `epps-field-id-${name}`,
      label,
      required,
      feedbackMessage,
      children: /* @__PURE__ */ jsx(
        DSControlledDateTimePicker,
        {
          ...renderedField,
          ...dateTimeProps,
          onCalendarOpenFocusedDay: getDefaultFocusedDate(),
          disabled: isDisabled,
          id: pathValue,
          type: ui?.type
        }
      )
    }
  ) });
};
export {
  Date
};

import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Grid } from "@elliemae/ds-grid";
import { DSHeader } from "@elliemae/ds-header";
import { Controller } from "react-hook-form";
import { Checkbox } from "./checkbox";
import { Combobox } from "./combobox";
import { Date } from "./date";
import { Textbox } from "./textbox";
import { Otherrealestateowned } from "./otherrealestateowned";
import { Borrowerdetails } from "./borrrowerdetails";
const Fieldgroup = ({
  schemaForm,
  rules,
  name,
  fields,
  visibleCheck,
  disableCheck,
  preview
}) => {
  const fieldComponentMap = {
    textbox: Textbox,
    combobox: Combobox,
    checkbox: Checkbox,
    date: Date
  };
  const renderField = (field) => {
    const {
      type,
      ui,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      component_id,
      id,
      label,
      required,
      helptext,
      options,
      selectMultiple
    } = field;
    const modelPath = field?.modelPath ?? field?.encompass_ids?.[0]?.modelPath;
    const key = type === "enum" ? "combobox" : type === "boolean" ? "checkbox" : type === "string" && ui.widget === "datepicker" ? "date" : "textbox";
    const Component = key ? fieldComponentMap[key] : null;
    if (!Component) return null;
    if (name === "Default and Bankruptcy" || name === "Supplemental Funding") {
      ui.col_size = 3;
    }
    return /* @__PURE__ */ jsx(
      Controller,
      {
        name: modelPath,
        render: ({ field: renderedField }) => /* @__PURE__ */ jsx(
          Component,
          {
            renderedField,
            schemaForm,
            rules: null,
            id,
            name: component_id,
            label,
            required,
            pathValue: modelPath,
            feedbackMessage: helptext,
            ui,
            options,
            isMulti: selectMultiple,
            visible: true,
            disabled: false,
            visibleCheck: null,
            disableCheck: null,
            preview
          }
        )
      }
    );
  };
  return name === "Other Real Estate Owned" ? /* @__PURE__ */ jsx(
    Otherrealestateowned,
    {
      schemaForm,
      fields,
      preview
    }
  ) : name === "Borrower Details" ? /* @__PURE__ */ jsx(
    Borrowerdetails,
    {
      schemaForm,
      fields,
      preview
    }
  ) : /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Grid, { span: 12, children: /* @__PURE__ */ jsx(
      DSHeader,
      {
        text: name,
        fontSize: 12,
        fontWeight: "bold",
        lineHeight: 1,
        style: { marginTop: "16px" },
        color: void 0,
        fontFamily: void 0,
        height: void 0,
        level: void 0,
        width: void 0
      }
    ) }),
    fields.map(renderField)
  ] });
};
export {
  Fieldgroup
};

import { jsx } from "react/jsx-runtime";
import { Grid } from "@elliemae/ds-grid";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { DSSingleComboBox } from "@elliemae/ds-form-single-combobox";
import { DSMultiComboBox } from "@elliemae/ds-form-multi-combobox";
import { useMemo, useState, useEffect } from "react";
import { useFormState } from "react-hook-form";
import {
  useSingleComboboxFieldUpdateLogic,
  useMultiComboboxFieldUpdateLogic
} from "./utils/fieldUpdateLogic";
import { evaluateRuleById } from "./utils/utils";
const Combobox = ({
  renderedField,
  schemaForm,
  rules,
  id,
  name,
  label,
  required,
  isMulti,
  pathValue,
  feedbackMessage,
  ui,
  options,
  visible,
  disabled,
  disableCheck,
  visibleCheck,
  preview
}) => {
  const singleComboboxFieldUpdateLogic = useSingleComboboxFieldUpdateLogic();
  const multiComboboxFieldUpdateLogic = useMultiComboboxFieldUpdateLogic();
  const [isVisible, setIsVisible] = useState(visible);
  const [isDisabled, setIsDisabled] = useState(disabled);
  const { errors } = useFormState({ control: schemaForm.control });
  const allComboboxOptions = useMemo(() => {
    const newOpts = options.filter((option) => option.visible).map((option) => ({
      dsId: `${option.id}`,
      type: "option",
      label: `${option.text}`,
      value: `${option.value}`
    }));
    return newOpts || [];
  }, [options]);
  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);
  useEffect(() => {
    const updateVisibility = () => {
      if (visibleCheck?.handler) {
        setIsVisible(
          evaluateRuleById(
            visibleCheck.handler,
            schemaForm.getValues(),
            rules,
            preview
          ) ? !visible : visible
        );
      }
    };
    const updateDisabledState = () => {
      if (disableCheck?.handler) {
        setIsDisabled(
          evaluateRuleById(
            disableCheck.handler,
            schemaForm.getValues(),
            rules,
            preview
          ) ? !disabled : disabled
        );
      }
    };
    const subscription = schemaForm.watch(() => {
      updateVisibility();
      updateDisabledState();
    });
    updateVisibility();
    updateDisabledState();
    return () => subscription.unsubscribe();
  }, [schemaForm]);
  return /* @__PURE__ */ jsx(Grid, { span: ui.col_size, style: { display: isVisible ? "block" : "none" }, children: /* @__PURE__ */ jsx(
    DSFormLayoutBlockItem,
    {
      name,
      label,
      inputID: name,
      required,
      feedbackMessage,
      hasError: !!errors[id],
      validationMessage: errors[id]?.message?.toString(),
      children: isMulti ? /* @__PURE__ */ jsx(
        DSMultiComboBox,
        {
          ...renderedField,
          allOptions: allComboboxOptions,
          selectedValues: Array.isArray(renderedField?.value) ? renderedField.value.map(
            (val) => allComboboxOptions.find(
              (option) => option.value === val.toString()
            )
          ).filter(Boolean) : [],
          onChange: multiComboboxFieldUpdateLogic(
            renderedField,
            schemaForm,
            id
          ),
          id: pathValue,
          disabled: isDisabled
        }
      ) : /* @__PURE__ */ jsx(
        DSSingleComboBox,
        {
          ...renderedField,
          allOptions: allComboboxOptions,
          onChange: singleComboboxFieldUpdateLogic(
            renderedField,
            schemaForm,
            id
          ),
          selectedValue: allComboboxOptions.find(
            (option) => option.value === renderedField?.value?.toString()
          ) || null,
          id: pathValue,
          disabled: isDisabled
        }
      )
    }
  ) });
};
export {
  Combobox
};

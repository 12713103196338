import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { setLoanDataValue } from '../../../../../components/Form/utils/common';
import { InputMaskForm } from '../../../../../components/Form';
import * as masks from '../../../../utils/constants';
import * as dependencies from '../../utils/dependencies';
import * as validations from './utils/validations';

export const CreditScoreField = () => {
  const dispatch = useDispatch();

  const handleSetLoanDataCreditScore = ({ value, borrowers }) => {
    const parseValue = ['', undefined, 'none'].includes(value) ? null : value;
    const newBorrowers = borrowers?.length
      ? borrowers.map((borrower) => ({
          ...borrower,
          borrowerFinanancial: {
            ...borrower.borrowerFinanancial,
            creditScore: parseValue,
          },
        }))
      : borrowers;
    dispatch(setLoanDataValue(newBorrowers, 'borrowers') as any);
  };

  return (
    <InputMaskForm
      required
      clearable
      maxLength={3}
      maskOpts={masks.DECIMAL_PLACES_NOT_PREFIX}
      name="representativeCreditScore"
      label="Representative Credit Score"
      dependencies={dependencies.CREDIT_SCORE}
      readOnly={validations.validateNoCreditScore}
      pathValue="borrowers[0].borrowerFinanancial.creditScore"
      customValue={({ value, customDependencies }) => {
        const { noCreditScores, borrowers } = customDependencies;
        if ((!isEmpty(borrowers) && noCreditScores) || !value) {
          handleSetLoanDataCreditScore({ borrowers, value: 0 });
          return 0;
        }
        return value;
      }}
      onBlur={({ customDependencies, value }) => {
        const { borrowers } = customDependencies;
        handleSetLoanDataCreditScore({ borrowers, value });
      }}
    />
  );
};

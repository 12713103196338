import { isEmpty, sortBy } from 'lodash';

export const getDocumentationType = ({
  customDependencies,
  documentationTypes,
}) => {
  const { documentationLevel } = customDependencies;
  const documentationType = documentationTypes.filter(
    (type) => type.documentationId === documentationLevel,
  );
  const isDocumentationType = isEmpty(documentationType)
    ? documentationTypes.filter((type) => type.documentationId === 1)
    : documentationType;

  return isDocumentationType;
};

export const getEmployment = ({ customDependencies, ...rest }) => {
  const documentationType = getDocumentationType({
    customDependencies,
    ...rest,
  } as any);
  const { selfEmployedBorrower, selfEmployedCoBorrower } = customDependencies;
  const employment = selfEmployedBorrower || selfEmployedCoBorrower ? 2 : 1;
  return documentationType[0].employment === 100 ? employment : 0;
};

export const getIncome = (data) => {
  const documentationType = getDocumentationType(data);
  return documentationType[0].incomee;
};

export const getAssets = (data) => {
  const documentationType = getDocumentationType(data);
  return documentationType[0].assets;
};

export const sortByDisplayOrder = (options) =>
  sortBy(options, ['displayOrder']);

import React, { memo } from 'react';
import { string } from 'prop-types';
import { DataTable } from '@elliemae/ds-data-table';
import { useLoanDataFieldSelector } from '../../../../../components/Form/utils/customHooks';
import { columns } from './utils/constants';
import { getDelinquenciesRows } from './utils/helpers';

export const Delinquencies = memo<any>(({ pathValue, ...rest }) => {
  const borrowerFinancialHistory = (useLoanDataFieldSelector(pathValue) as any)
    .value;

  const rows = getDelinquenciesRows(borrowerFinancialHistory);

  const onChangeBorrowerFinancialHistory = (val) => {
    const { onChange } = rest;
    onChange({ value: borrowerFinancialHistory, pathValue, newKeyValue: val });
  };

  const handleChange = (key, value) => {
    onChangeBorrowerFinancialHistory({ key, value });
  };

  return (
    <DataTable
      autoHeight
      data={rows}
      columns={columns}
      colsLayoutStyle="auto"
      noResultsMessage="No Results Found"
      noResultsSecondaryMessage="You do not have any Programs at this time."
      cellRendererProps={{ handleChange }}
    />
  );
});

(Delinquencies as any).defaultProps = {
  pathValue: '',
};

(Delinquencies as any).propTypes = {
  pathValue: string,
};

import Session, { IS_BUYSIDE } from '../../../../common/services/Session';
import { useLookup } from '../../../../data/useSelectors';
import { QuoteInsuranceButton } from './components/QuoteInsuranceButton';

export const QuoteInsuranceButtonWrapper = () => {
  const clientSettings = useLookup('clientSettings') || [];
  const isBuyside = Session.get(IS_BUYSIDE);
  const isMIQuotesEnabled =
    clientSettings[0]?.options?.isMIQuotesEnabled || false;

  return isMIQuotesEnabled && !isBuyside && <QuoteInsuranceButton />;
};

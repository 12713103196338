import { jsx } from "react/jsx-runtime";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { DSInputText } from "@elliemae/ds-form-input-text";
import { Grid } from "@elliemae/ds-grid";
import { useState, useEffect } from "react";
import { useFormState } from "react-hook-form";
import { useTextboxFieldUpdateLogic } from "./utils/fieldUpdateLogic";
import { evaluateRuleById } from "./utils/utils";
const PERCENT_VALUES = {
  allowNegative: true,
  decimalPlaces: 3,
  suffix: "%",
  includeThousandsSeparator: false
};
const NOT_DECIMAL_PLACES = {
  allowNegative: true,
  decimalPlaces: 0,
  prefix: "$"
};
const Textbox = ({
  renderedField,
  schemaForm,
  rules,
  id,
  name,
  label,
  required,
  pathValue,
  feedbackMessage,
  ui,
  visible,
  disabled,
  disableCheck,
  visibleCheck,
  preview
}) => {
  const textboxUpdateLogic = useTextboxFieldUpdateLogic();
  const [isVisible, setIsVisible] = useState(visible);
  const [isDisabled, setIsDisabled] = useState(disabled);
  const { errors } = useFormState({ control: schemaForm.control });
  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);
  useEffect(() => {
    const updateVisibility = () => {
      if (visibleCheck?.handler) {
        setIsVisible(
          evaluateRuleById(
            visibleCheck.handler,
            schemaForm.getValues(),
            rules,
            preview
          ) ? !visible : visible
        );
      }
    };
    const updateDisabledState = () => {
      if (disableCheck?.handler) {
        setIsDisabled(
          evaluateRuleById(
            disableCheck.handler,
            schemaForm.getValues(),
            rules,
            preview
          ) ? !disabled : disabled
        );
      }
    };
    const subscription = schemaForm.watch(() => {
      updateVisibility();
      updateDisabledState();
    });
    updateVisibility();
    updateDisabledState();
    return () => subscription.unsubscribe();
  }, [schemaForm]);
  return /* @__PURE__ */ jsx(Grid, { span: ui.col_size, style: { display: isVisible ? "block" : "none" }, children: /* @__PURE__ */ jsx(
    DSFormLayoutBlockItem,
    {
      name,
      label,
      required,
      feedbackMessage,
      hasError: !!errors[id],
      validationMessage: errors[id]?.message?.toString(),
      children: /* @__PURE__ */ jsx(
        DSInputText,
        {
          ...renderedField,
          "data-testid": "textbox",
          value: renderedField?.value ?? "",
          onChange: textboxUpdateLogic(renderedField, schemaForm, id),
          disabled: isDisabled,
          id: pathValue
        }
      )
    }
  ) });
};
export {
  Textbox
};

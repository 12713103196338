import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SchemaSearchForm } from '@frontend/pricing-search';
import { lookupsActions } from '../../store/lookups';
import {
  StyledEppsContainerBody,
  StyledEppsContainerHeader,
  StyledEppsFormWrapper,
  StyledEppsParentView,
  StyledEppsWrapper,
} from '../global/styles/styled';
import { CustomFieldsForm } from './components/CustomFieldsForm';
import { ErrorBanner } from './components/ErrorBanner';
import { FinanceInformationForm } from './components/FinanceInformationForm';
import { FooterForm } from './components/FooterForm';
import { HeaderForm } from './components/HeaderForm';
import { LoanInformationForm } from './components/LoanInformationForm';
import { ProductInformationForm } from './components/ProductInformationForm';
import { SubjectPropertyInformationForm } from './components/SubjectPropertyInformationForm';

export const SearchForm = ({ customSchema, schemaForm, showCustomSearch }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(lookupsActions.getGeocodesDataAction({ dispatch } as any));
    dispatch(lookupsActions.getLookupsDataAction({ dispatch } as any));
  }, []);

  return (
    <StyledEppsParentView>
      <StyledEppsContainerBody>
        <StyledEppsWrapper>
          <StyledEppsContainerHeader>
            <ErrorBanner />
          </StyledEppsContainerHeader>
          <HeaderForm />
          <StyledEppsFormWrapper>
            {showCustomSearch ? (
              <SchemaSearchForm
                schema={customSchema}
                schemaForm={schemaForm}
                preview={false}
              />
            ) : (
              <>
                <LoanInformationForm />
                <CustomFieldsForm />
                <ProductInformationForm />
                <FinanceInformationForm />
                <SubjectPropertyInformationForm />
              </>
            )}
          </StyledEppsFormWrapper>
        </StyledEppsWrapper>
      </StyledEppsContainerBody>
      <FooterForm schemaForm={schemaForm} showCustomSearch={showCustomSearch} />
    </StyledEppsParentView>
  );
};

import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from '../../../common/utils/shared';
import { itemsFormat } from './constants';

export const optionBody = ({ id, lookup, ...rest }) => ({
  dsId: `${lookup}-${id || 'none'}`,
  type: 'option',
  ...rest,
});

export const transformOfficeUsers = (officeUsers) => {
  const result: { dsId: string; type: string; label: string }[] = [];
  let currentOffice = null;
  officeUsers.forEach((obj) => {
    if (obj.office !== currentOffice) {
      result.push({
        dsId: `officeUsers-${uuidv4() || 'none'}`,
        type: 'section',
        label: obj.office,
      });
      currentOffice = obj.office;
    }
    result.push(obj);
  });
  return result;
};

export const generateComboboxOptions = (
  lookup = '',
  options: {
    label: string;
    key: string;
    value: number;
    buydownTypeId: number;
    buydownContributorTypeId: number;
    feeValue: null;
  }[] = [],
) => {
  const { props = {}, ...rest } = itemsFormat[lookup] || {};
  const noneItem = { lookup, label: 'none', value: 'none' };
  const comboboxOptions =
    Array.isArray(options) &&
    options.map((item) => {
      const extraProps = {};
      const label = `${item[props?.label] ?? item.label ?? item.key}`;
      let value = item[props?.value] ?? item.value ?? 'none';

      if (
        [
          'bankruptcy',
          'foreclosure',
          'buydownType',
          'prepayPenaltyTerms',
          'buydownContributorType',
          'fee',
        ].includes(lookup) &&
        (item.value === 0 ||
          item.buydownTypeId === 0 ||
          item.buydownContributorTypeId === 0 ||
          item.feeValue === null)
      ) {
        value = 0;
      }

      Object.entries(rest?.extraProps || {}).forEach(([key, path]) => {
        extraProps[key] = item[path as string];
      });
      const nonFormat = rest?.nonFormat ? item : { label, value, id: uuidv4() };
      return optionBody({ lookup, ...extraProps, ...nonFormat } as any);
    });
  if (rest.noneOption && Array.isArray(comboboxOptions))
    comboboxOptions.unshift(optionBody(noneItem as any));
  return comboboxOptions || [];
};

export const generateDocTypesComboboxOptions = ({
  key,
  documentationTypes,
  options,
}) => {
  const setOptions: any[] = [];
  if (!isEmpty(documentationTypes)) {
    const elements = [
      ...new Set(
        documentationTypes.map((el) => el[key] || (el[key] === 0 && 0)),
      ),
    ];
    if (!isEmpty(elements)) {
      elements.map((el) => {
        const isDocumentOption = options[el as any];
        return isDocumentOption.map((opt) =>
          setOptions.push(optionBody({ id: opt.value, lookup: key, ...opt })),
        );
      });
    }
  }
  return setOptions;
};

export const generateOfficeUsersComboboxOptionsWithItemLayout = (
  lookup = '',
  options: {
    firstName: string;
    lastName: string;
    userId: string;
    login: any;
    office: any;
  }[] = [],
) => {
  const { ...rest } = itemsFormat[lookup] || {};
  const noneItem = { lookup, label: 'none', value: 'none' };

  const comboboxOptions =
    Array.isArray(options) &&
    options.map((item) => {
      const extraProps = {};
      const label = `${item.firstName} ${item.lastName}`;
      const value = item.userId;
      const { login } = item;
      const { office } = item;
      Object.entries(rest?.extraProps || {}).forEach(([key, path]) => {
        extraProps[key] = item[path as any];
      });
      const nonFormat = rest?.nonFormat
        ? item
        : { label, value, login, office, id: uuidv4() };
      return optionBody({ lookup, ...extraProps, ...nonFormat } as any);
    });
  if (rest.noneOption && Array.isArray(comboboxOptions))
    comboboxOptions.unshift(optionBody(noneItem as any));
  return transformOfficeUsers(comboboxOptions) || [];
};

/* eslint-disable @typescript-eslint/await-thenable */
import { DSModal } from '@elliemae/ds-modal';
import { DSTab, DSTabs } from '@elliemae/ds-tabs';
import { cloneDeep } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { calculateGovtUpfrontAmount } from '../../common/app-helpers';
import {
  UPDATE_ADJUSTMENT_DETAILS_CACHE,
  UPDATE_LOANINFO,
  UPDATE_PROPERTY_DETAILS_CACHE,
} from '../../common/constants/ActionConstants';
import { mockLoanData } from '../../common/constants/SearchSampleData';
import {
  getRates,
  getWebhookRates,
  setAllRatesRowData,
} from '../../common/Qualification/QualificationActions';
import {
  getLoanCatcherAdjustments,
  processLoancatcherAdjustments,
} from '../../common/services/lockSummaryServices';
import Rights from '../../common/services/Rights';
import { getLoanLimits } from '../../common/services/searchFormServices';
import Session, {
  APPLICATION_NAME,
  IS_BUYSIDE,
  USER_TYPE,
} from '../../common/services/Session';
import { calcDeltas } from '../../common/utils/calculateTarget';
import createAction from '../../common/utils/createAction';
import { DataProvider } from '../../common/utils/customHooks/useAppData';
import { isEmpty } from '../../common/utils/shared';
import { openGlobalErrorModalAction } from '../../data/globalErrorModal/actionCreators';
import { setRedirectTabAction } from '../../data/landing/actions';
import { setLoanLimits } from '../../data/lockSummary/actions';
import { fetchCurrentPersonaAccessRules } from '../../data/personaAccess/actionCreators';
import { closeLoader } from '../../data/screenLoader/actions';
import {
  useLoanInformationField,
  useLockedProgramSelector,
  useOriginalLoanInformationSelector,
  usePropertyDetailsCache,
  useUserData,
} from '../../data/useSelectors';
import { PATH_SEARCH_FOR_PRODUCTS } from '../../route/constants';
import { AppDispatch } from '../../store';
import { lookupsActions } from '../../store/lookups';
import AdjustmentDetailsView from '../AdjustmentDetails/AdjustmentDetailsView';
import { useLookupOptions } from '../components/Form/utils/customHooks';
import {
  StyledEppsContainerBody,
  StyledEppsParentView,
  StyledEppsWrapper,
} from '../global/styles/styled';
import { AllRatesView } from './components/AllRatesView';
import { BestExGrid } from './components/BestExGrid';
import { ClassicGrid } from './components/ClassicGrid';
import { IneligibleProductsGrid } from './components/IneligiblePrograms';
import { IneligibleUnavailableModal } from './components/IneligibleUnavailableModal';
import { QuickSearch } from './components/QuickSearch';
import { applyFilter, getTarget } from './helpers';

const loanDataSelector = (res) => res?.EPPS?.loanInfo ?? mockLoanData;
const qualificationResultSelector = ({ EPPS }) =>
  (EPPS && EPPS.loanPrograms) || {};
const loanLimitsSelector = (epps) => epps?.lockSummary?.loanLimits;

export const SearchResult = () => {
  const dispatch = useDispatch<AppDispatch>();
  const targetValue = useLoanInformationField('target');
  const qualificationResult = useSelector(qualificationResultSelector);
  const loanLimitsResult = useSelector(loanLimitsSelector);
  const loanData = useSelector(loanDataSelector);
  const { requestAction, requestLockStatus } = loanData;
  const relockFlag = requestAction === 2 && requestLockStatus === 2;
  const allRatesRowData = useSelector(
    (state: any) => state.EPPS.allRatesRowData,
  );
  const action = createAction(UPDATE_LOANINFO);
  const tpoPricingGroup = useSelector(
    ({ personaAccess }) => personaAccess.pricingGroup,
  );
  const [loanPrograms, setLoanPrograms] = useState([]);
  const [filters] = useState({});
  const [target, setTarget] = useState(getTarget(loanData));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshPrograms, setRefreshPrograms] = useState(false);
  const [data, setData] = useState({});
  const [user] = useState(Session.get(USER_TYPE));
  const [activeTab, setActiveTab] = useState('Classic');
  const refEligible = useRef<any>('');
  const refIneligible = useRef<any>('');
  const refBestExecution = useRef<any>('');
  const [loanLimitsData, setloanLimitsData] = useState([]);
  const [eligibleProducts, setEligibleProducts] = useState(0);
  const [ineligibleProducts, setIneligibleProducts] = useState(0);
  const [bestExecution, setBestExecution] = useState(0);
  const { focusIneligible, status } = useLockedProgramSelector();
  const propertyDetailsCache = usePropertyDetailsCache() || [];
  const govtUpfrontFees = useLookupOptions('fee') || [];
  let ltv = useOriginalLoanInformationSelector('ltv');
  const originalLoanInformation = useOriginalLoanInformationSelector();
  const buysideAdjustments = useSelector(({ epps }) =>
    Object.values(epps.origin.buySideAdjustments ?? {}).flat(1),
  );

  const isBuySide = Session.get(IS_BUYSIDE);

  const getBestExRows = !isEmpty(qualificationResult.productGroups)
    ? qualificationResult.productGroups.map((row) => ({ ...row, parent: true }))
    : [];
  let { defaultUserView = 'BestEx' } = useUserData();
  const tabsToRender = useRef<any[]>([]);

  const updateAdjustmentDetailsCache = createAction(
    UPDATE_ADJUSTMENT_DETAILS_CACHE,
  );
  const updatePropertyDetailsCache = createAction(
    UPDATE_PROPERTY_DETAILS_CACHE,
  );

  const originLoanLoaded = useSelector(
    ({ EPPS }) => EPPS?.loanInfo && !EPPS?.loanInfo.isMockData,
  );

  useEffect(() => {
    if (isEmpty(tabsToRender.current)) {
      if (Rights.accessStandardView) {
        tabsToRender.current.push({
          tabId: 'Classic',
          key: uuidv4(),
          title: 'Standard',
        });
      }
      if (Rights.accessBestexView) {
        tabsToRender.current.push({
          tabId: 'BestEx',
          key: uuidv4(),
          title: 'BestEx',
          style: relockFlag ? { display: 'none' } : {},
        });
      }
      tabsToRender.current.splice(1, 0, {
        tabId: 'Ineligible',
        key: uuidv4(),
        title: 'Ineligible Programs',
        style: relockFlag ? { display: 'none' } : {},
      });
      if (!Rights.accessBestexView || relockFlag) {
        defaultUserView = 'Classic';
      }
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setActiveTab(defaultUserView);
  }, [defaultUserView]);

  useEffect(() => {
    setloanLimitsData(loanLimitsResult);
  }, [loanLimitsResult]);

  useEffect(() => {
    setTarget(getTarget(loanData));
  }, [loanData]);

  useEffect(() => {
    if (focusIneligible) {
      setActiveTab('Ineligible');
    }
  }, [focusIneligible]);

  // calculate deltas
  const handleCalculateDeltas = () => {
    const calculateTarget = getTarget(loanData);
    const { loanPrograms: programs } = qualificationResult;
    const cloneLoanPrograms = [...programs];
    const getDeltas = calcDeltas(cloneLoanPrograms, calculateTarget);
    setLoanPrograms(getDeltas);
  };

  useEffect(() => {
    const { loanPrograms: programs } = qualificationResult;
    if (programs) {
      handleCalculateDeltas();
    }
  }, [qualificationResult, loanData, targetValue]);

  const qualifyPrograms = async (newLoanData?: any, isSearchPage = true) => {
    setRefreshPrograms(true);
    const loan = newLoanData || loanData;
    const isHeloc = !!(loan.loanType === 'HELOC');
    const applicationName = Session.get(APPLICATION_NAME);
    if (isHeloc) {
      loan.loanType = 'HELOC';
      if (loan.loanInformation.lienPosition === 1) {
        loan.loanInformation.helocInitialDraw =
          loan.loanInformation.firstMortgageAmount;
      } else if (loan.loanInformation.lienPosition === 2) {
        loan.loanInformation.helocInitialDraw =
          loan.loanInformation.secondMortgageAmount;
      }
    } else {
      loan.loanType = 'Conventional';
      loan.loanInformation.helocInitialDraw = null;
    }
    if (applicationName === 'TPO') {
      loan.facadeUserID = tpoPricingGroup;
    }
    // only calculate fee amount if not called from search page
    if (!isSearchPage) {
      const loanLtv = loan?.loanInformation?.ltv;
      if (loanLtv) {
        ltv = loanLtv;
      }
      const { feeAmount, feeAmountFinanced, feeAmountPaidinCash } =
        calculateGovtUpfrontAmount(
          loan,
          originalLoanInformation,
          govtUpfrontFees,
          ltv,
        );
      if (feeAmount) {
        loanData.loanInformation.financedAmount = 0;
        loanData.loanInformation.feeAmount = feeAmount || 0;
        loanData.loanInformation.feeAmountFinanced = feeAmountFinanced || 0;
        loanData.loanInformation.feeAmountPaidinCash = feeAmountPaidinCash || 0;
      }
    }

    const copyLoan = cloneDeep(loan);
    if (isBuySide) {
      copyLoan.relockRequest.specificProgramID = null;
      copyLoan.relockRequest.specificRateDataID = null;
    }
    if (user && user.userType === 'StandAlone') {
      await dispatch(getRates(copyLoan));
    } else {
      await dispatch(getWebhookRates(copyLoan));
      if (
        propertyDetailsCache?.state !== copyLoan?.property?.state ||
        propertyDetailsCache?.county !== copyLoan?.property?.county ||
        propertyDetailsCache?.zip !== copyLoan?.property?.zip
      ) {
        // Property details have changed, query loan limits again
        const updatedPropertyDetailsCache = {
          state: loanData.property.state,
          county: loanData.property.county,
          zip: loanData.property.zip,
        };
        dispatch(updatePropertyDetailsCache(updatedPropertyDetailsCache));
        getLoanLimits(loanData).then((res) => {
         dispatch(setLoanLimits(res));
        });
      }
    }
    dispatch(updateAdjustmentDetailsCache([]));

    const loanCatcherAdjustments = await getLoanCatcherAdjustments(
      loan.loanInformation,
    );
    if (loanCatcherAdjustments?.length > 0) {
      processLoancatcherAdjustments(
        dispatch,
        loan,
        buysideAdjustments,
        loanCatcherAdjustments,
      );
    }
    if (newLoanData) dispatch(action(copyLoan));
    dispatch(fetchCurrentPersonaAccessRules() as any);
    setRefreshPrograms(false);
  };

  useEffect(() => {
    if (allRatesRowData) {
      const refreshedProgram = qualificationResult.loanPrograms.find(
        ({ programID }) => programID === allRatesRowData.programID,
      );
      dispatch(setAllRatesRowData(refreshedProgram));
    }
  }, [qualificationResult.loanPrograms]);

  useEffect(() => {
    if (!originLoanLoaded) {
      return;
    }

    const getData = async () => {
      const { loanPurpose } = loanData.loanInformation;
      if (loanPurpose === 99) {
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: 'Loan Purpose of Other is not supported.',
            onCloseCallback: () => {
              dispatch(setRedirectTabAction(PATH_SEARCH_FOR_PRODUCTS));
              dispatch(
                lookupsActions.getLookupsDataAction({ dispatch } as any),
              );
            },
          }) as any,
        );
      } else {
        await Promise.all([
          qualifyPrograms(),
          dispatch(lookupsActions.getLookupsDataAction({ dispatch } as any)),
        ]);
      }
      dispatch(closeLoader());
    };
    getData();
    setloanLimitsData(loanLimitsResult);
  }, [originLoanLoaded]);

  useEffect(() => {
    setTarget(getTarget(loanData));
  }, [loanData]);

  const showDetailsModal = (
    e?: any,
    idLoan?: any,
    programId?: any,
    rateDataId?: any,
    lienPosition?: any,
  ) => {
    e.preventDefault();
    setData({
      loanId: idLoan,
      lienPosition,
      rateDataId,
      programId,
    });
    setIsModalOpen(!isModalOpen);
  };

  const ProgramDetailsModal = () => (
    <DSModal
      isOpen={isModalOpen}
      modalTitle="Program Details"
      onClose={(e) => showDetailsModal(e, null, null)}
      showFooter={false}
    >
      <p>
        <AdjustmentDetailsView data={data} />
      </p>
    </DSModal>
  );

  if (allRatesRowData) {
    return (
      <AllRatesView
        allRatesData={allRatesRowData}
        loanData={loanData}
        qualifyPrograms={qualifyPrograms}
      />
    );
  }

  return (
    <StyledEppsParentView overflow="hidden">
      <StyledEppsContainerBody className="search-result">
        <StyledEppsWrapper paddingBottom="0">
          {!isBuySide && (
            <div style={{ paddingTop: '16px' }}>
              <QuickSearch onSearch={qualifyPrograms} />
            </div>
          )}
          {isBuySide && loanPrograms.length !== 0 && status !== 'eligible' && (
            <IneligibleUnavailableModal />
          )}
          <DataProvider
            value={{
              refEligible,
              refIneligible,
              refBestExecution,
              setEligibleProducts,
              setIneligibleProducts,
              setBestExecution,
            }}
          >
            {!isEmpty(Rights) && !isEmpty(tabsToRender.current) && (
              <DSTabs
                animated={false}
                height="100%"
                activeTab={activeTab}
                onTabChange={setActiveTab}
              >
                {tabsToRender.current.map(({ tabId, key, title, style }) => {
                  const counters = {
                    Classic: eligibleProducts,
                    Ineligible: ineligibleProducts,
                    BestEx: bestExecution,
                  };
                  let componentToRender: any = null;
                  if (tabId === 'Classic') {
                    componentToRender = () => (
                      <ClassicGrid
                        loanPrograms={loanPrograms}
                        refreshPrograms={refreshPrograms}
                        onSearch={qualifyPrograms}
                        applyFilter={() => {
                          refEligible.current = false;
                          refIneligible.current = false;
                          refBestExecution.current = false;
                          return applyFilter(filters);
                        }}
                        updateAdjustmentDetailsCache={
                          updateAdjustmentDetailsCache
                        }
                      />
                    );
                  }
                  if (tabId === 'BestEx') {
                    componentToRender = () => (
                      <BestExGrid
                        productGroups={getBestExRows}
                        loanPrograms={loanPrograms}
                        target={target}
                        ratesBestEx={qualificationResult.ratesBestEx}
                        lockDaysBestEx={qualificationResult.lockDays}
                        onSearch={qualifyPrograms}
                        updateAdjustmentDetailsCache={
                          updateAdjustmentDetailsCache
                        }
                      />
                    );
                  }
                  if (tabId === 'Ineligible') {
                    componentToRender = () => (
                      <IneligibleProductsGrid
                        loanPrograms={loanPrograms}
                        loanLimits={loanLimitsData}
                        refreshPrograms={refreshPrograms}
                      />
                    );
                  }
                  return (
                    <DSTab
                      tabId={tabId}
                      title={`${title} (${counters[tabId]})`}
                      key={key}
                      style={style}
                    >
                      {componentToRender()}
                    </DSTab>
                  );
                })}
              </DSTabs>
            )}
          </DataProvider>
          <ProgramDetailsModal />
        </StyledEppsWrapper>
      </StyledEppsContainerBody>
    </StyledEppsParentView>
  );
};

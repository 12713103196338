export const handleIsBuyDownType = ({ customDependencies }) => {
  const { buydownType } = customDependencies;
  return !buydownType;
};

export const handleTargetPriceField = ({ customDependencies }) => {
  const { targetLabel } = customDependencies;
  return !!(targetLabel === 'Rate');
};

export const handleTargetRateField = ({ customDependencies }) => {
  const { targetLabel } = customDependencies;
  return !!(targetLabel === 'Price');
};

export const compensationValue = ({ value }) =>
  value === 2 ? 'Lender-Paid' : 'Borrower-Paid';

export const parseCompensationValue = (value) => {
  let getValue: number | null = null;
  if (value) {
    if (value === 'Borrower-Paid') {
      getValue = 1;
    } else {
      getValue = 2;
    }
  }

  return getValue;
};

export const hideAllButtonComboboxStyles = (customMenuStyles = {}) => ({
  menu: (provided) => ({
    ...provided,
    ...customMenuStyles,
    '& > div > div:first-child': {
      '& > div:first-child': {
        '& > div > button:first-child': {
          visibility: 'hidden',
        },
      },
    },
  }),
});

export const productTypeValidation = ({ productType, lookupsLoanTerms }) => {
  let isHELOC = false;
  let hasError = false;
  let errorMessage = '';
  if (Array.isArray(productType) && lookupsLoanTerms) {
    const loanIsHELOC = lookupsLoanTerms
      .filter((elem) => productType.includes(elem.value))
      .some((elem) => elem.heloc);
    const loanIsConventional = lookupsLoanTerms
      .filter((elem) => productType.includes(elem.value))
      .some((elem) => !elem.heloc);

    isHELOC = loanIsHELOC;

    if (loanIsHELOC && loanIsConventional) {
      hasError = true;
      errorMessage = 'HELOC cannot be selected with any other loan term.';
    }
    if (productType.length > 8) {
      hasError = true;
      errorMessage = 'Please specify 8 or fewer loan terms.';
    }
  }
  return { value: isHELOC, hasError, errorMessage };
};
